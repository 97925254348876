import React from 'react'
import Deboy from '../../images/dboy3.png'
import Elo from '../../images/elo.png'
import Mb from '../../images/mb.png'
import Jasi from '../../images/jasi.png'
import Biggie from '../../images/bigg.png'
import Ola from '../../images/ola.png'
import Hz from '../../images/hz.png'
import Lekan from  '../../images/lekan2.png'
import Len from  '../../images/len.png'
import Pedro from  '../../images/pedro.png'
import Queen from  '../../images/queen.png'
import Rachel from  '../../images/rachel2.png'
import Far from  '../../images/faro.png'
import David from '../../images/david.png'
import ProfileComponent from './MembersCom'
function Members() {
  return (
    <div className=' bg-white p-4 md:p-10'>
        <h1 className=' text-center  text-2xl mb-6 md:mb-16 mt-8 text-[#1E222B] font-[700] md:text-5xl '>Team Members</h1>
        <div className=' grid md:grid-cols-3 gap-4'>
            <ProfileComponent
                img={Jasi}
                name='Adejumo kabir Babatunde'
                title='Chairman'
                iglink='https://www.instagram.com/invites/contact/?i=11skiw1vcc2oy&utm_content=1kucnga'
            />
            <ProfileComponent
                img={Ola}
                name='Olatidoye Adegboyega'
                title='Director of Operations'
                iglink='https://www.instagram.com/invites/contact/?i=1o1qnwsxxyzir&utm_content=1zza3sc'
            />
             <ProfileComponent
                img={Hz}
                name='Adewole Taiwo Hazan'
                title='Label Manager'
            />
            <ProfileComponent
                img={Biggie}
                name='Idowu Moshood'
                title='Chief Adviser'
                iglink='https://instagram.com/papichula201?igshid=YmMyMTA2M2Y='
            />
            <ProfileComponent
                img={Deboy}
                name='Babalola Daniel'
                title='Publicity Manager 1'
                iglink='https://www.instagram.com/deeboystyles/'
                twilink='https://twitter.com/parole_master'
            />
             <ProfileComponent
                img={Pedro}
                name='Olubiyi Pelumi Ayodeji'
                title='Publicity Manager 2'
                iglink='https://instagram.com/only1pedro_?igshid=YmMyMTA2M2Y='
                twilink='https://twitter.com/Only1Pedro_'
            />
            
            <ProfileComponent
                img={Rachel}
                name='Olawunmi Racheal'
                title='Welfare Director'
                iglink='https://www.instagram.com/invites/contact/?i=1eafleysmt2no&utm_content=lvo3v5h'
                twilink='https://twitter.com/rachealola01'
            />
            <ProfileComponent
                img={Mb}
                name='Aremu Mubarak Dolapo'
                title='Content Director 1'
                iglink='https://instagram.com/mb_banks1?igshid=Yzg5MTU1MDY='
                twilink='https://twitter.com/MubarakAremu1?s=09'
            />
            <ProfileComponent
                img={Elo}
                name='Ajasa Samuel'
                title='Content Director 2'
                iglink='https://instagram.com/elomiran_the_sage?igshid=YmMyMTA2M2Y='
                twilink='https://twitter.com/elomiran?t=0iRlQT4zdghmZODuypsgww&s=09'
            />
            <ProfileComponent
                img={Queen}
                name='Emasah Mary Oghenetega'
                title='General Secretary'
                iglink='http://instagram.com/emasahmary'
                twilink='http://twitter.com/EmasahOg'
            />
           
            <ProfileComponent
                img={Lekan}
                name='Lawal Quadri'
                title='BUSINESS MANAGER '
                iglink='https://instagram.com/lekan3283?r=nametag'
            />
             <ProfileComponent
                img={Far}
                name='Seidu Adebayo Farouq '
                title='Logistics Manager'
                iglink='https://instagram.com/p.h.a.r.h.u.q?igshid=YWJhMjlhZTc='
                twilink='https://twitter.com/pha__rhuq'
            />
             <ProfileComponent
                img={Len}
                name='Idowu Adedolapo '
                title='Strategy Manager'
                iglink='https://www.instagram.com/legendarywashs/'
            />
            <ProfileComponent
                img={David}
                name='David Ajibade'
                title='Software Engineer'
                iglink='https://www.instagram.com/inioluwa_david/'
                twilink='https://twitter.com/inioluwa_davidd?t=XpnnniHuHVXYgreTe7M-aw&s=09'
            />
        </div>
    </div>
  )
}

export default Members