import React, {useEffect} from 'react';
import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import './App.css';
import Scrolltotop from './Components/Scrolltotop';
import Academic from './Pages/Academic';
import Entertainment from './Pages/Entertainment';
import Entrepreneurship from './Pages/Entrepreneurship';
import Fosa from './Pages/Fosa';
import FreshStudent from './Pages/Fresh Student';
import General from './Pages/General';
import Home from './Pages/Home';
import SpecialRecognition from './Pages/Special Recognition';
import ReactGA from 'react-ga'
import Team from './Pages/Team';
import WOW from 'wowjs';
import Ticket from './Pages/Ticket';
ReactGA.initialize('UA-253511651-1')
function App() {
  
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <BrowserRouter>
    <Scrolltotop/>
      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/fosa' element={<Fosa/>}/>
        <Route path='/fosa/general' element={<General/>}/>
        <Route path='/fosa/entertainment' element={<Entertainment/>}/>
        <Route path='/fosa/academic' element={<Academic/>}/>
        <Route path='/fosa/entrepreneurship' element={<Entrepreneurship/>}/>
        <Route path='/fosa/recognition' element={<SpecialRecognition/>}/>
        <Route path='/fosa/freshstudent' element={<FreshStudent/>}/>
        <Route path='/team' element={<Team/>}/>
        <Route path='/ticket' element={<Ticket/>}/>
        <Route path='*' element={<Home />}/>
      </Routes>
     </BrowserRouter>
  );
}

export default App;
