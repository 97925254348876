import React from 'react'

function PastEventCom({img, header, sub}) {
  return (
    <div className=' m-3'>
        <img src={img} alt='img' className=' rounded-lg mb-2'/>
        <h1 className=' text-[24px] font-[700] text-black mb-2'>{header}</h1>
        <p className=' mb-2'>{sub}</p>
    </div>
  )
}

export default PastEventCom