import React from 'react'


function Vote() {
    
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    
   
    <h1 className=' text-4xl text-white text-center text-[700] mb-7'>Non voting Category</h1>
    <h1 className=' text-2xl text-white text-[700] mb-7'>HALL OF FAME AND PHILANTHROPIST</h1>
    <p className=' mb-6 text-white'> <span className=' text-black p-2  rounded-lg mr-2 bg-primary-color1'>1</span>TOYE OKEDARA CHIPPY pka Chippy</p>
    <p className=' mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>2</span>ADAMS </p>
    <p className=' mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>3</span>NEW BORN</p>
    <p className=' mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>4</span>ALHAJI JASI </p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>5</span>OLALEKAN BORTE</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>6</span>HEFFIZY</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>5</span>PROVERBIAL</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>6</span>ADEOLU CEO GENESIS 360 LTD </p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>7</span> LEGGY</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>5</span>MR LEKKY D</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>6</span>ABDULRAHMON KAFIDIPE CEO MARKSMAN</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>7</span> ABDUL CEO UNIFIELD</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>7</span> HEPTHA</p>
    <p className='mb-6 text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>7</span> Rami Ekun</p>
    </div>
  )
}

export default Vote