import React, {useEffect} from 'react'
import Aboutus from '../../Components/Home/Aboutus'
import Contact from '../../Components/Home/Contact'
import Footer from '../../Components/Footer'
import PastEvent from '../../Components/Home/PastEvents'
import Services from '../../Components/Home/Services'
import Sponsors from '../../Components/Home/Sponsors'
import TopSec from '../../Components/Home/TopSec'
import Upcomingevent from '../../Components/Home/Upcomingevent'
import Values from '../../Components/Home/Values'
import Vision from '../../Components/Home/vision'
import NavBar from '../../Components/Navbar'
import ReactGA from 'react-ga'
function Home() {
  useEffect(() =>{
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <div>
        <NavBar/>
        <TopSec/>
        <Aboutus/>
        <Vision/>
        <Services/>
        <Values/>
        <PastEvent/>
        <Upcomingevent/>
        <Sponsors/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Home