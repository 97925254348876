import axios from "axios";
const API = "https://apis.jaslineentertainment.com/api"


const votesNomines = (data) =>{
   
    return axios.post(API +'/post/add_votes.php', data );
}

const postService ={
    votesNomines
}

export default postService