import React from 'react'
import Value from '../../images/value.png'
import Integrity from '../../images/integrity.png'
import Exce from '../../images/exce.png'
import Pro from '../../images/prof.png'
import Resourceful from '../../images/reso.png'
import Team from '../../images/team.png'
import Creativity from '../../images/creativity.png'
import ValueComponent from './ValueComponent'
function Values() {
  return (
    <div className=' p-8 md:p-16  grid md:grid-cols-2 gap-4'>
        <div>
            <h1 className=' text-[48px] font-[700] mb-2 wow animate__animated animate__slideInLeft'>Core Values</h1>
            <img src={Value} alt='Valuess' className=' hidden md:block wow animate__animated animate__slideInLeft'/>
        </div>
        <div>
            <ValueComponent
                img={Integrity}
                header='Integrity'
                sub='Our brand maintains transparency and honesty in all business dealings.'
            />
            <ValueComponent
                img={Exce}
                header='Excellence'
                sub='Our image exudes royalty, which means our services are creme de là creme.'
            />
            <ValueComponent
                img={Pro}
                header='Professionalism'
                sub='We provide the right attitude to work in all professional settings.'
            />
            <ValueComponent
                img={Resourceful}
                header='Resourcefulness'
                sub='We command one of the most robust human and material resources in the community we deal in.'
            />
            <ValueComponent
                img={Integrity}
                header='Integrity'
                sub='Our brand maintains transparency and honesty in all business dealings.'
            />
            <ValueComponent
                img={Team}
                header='Teamwork'
                sub='We ensure that all hands are on deck to provide timely and quality delivery of our professional services.'
            />
             <ValueComponent
                img={Creativity}
                header='Creativity'
                sub='We skillfully realize your unique dreams through a magical combination of the right human and material resources.'
            />
        </div>
    </div>
  )
}

export default Values