import React,  { useEffect, useState } from 'react'

function Timer() {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const deadline = "August, 18, 2024";
  
    const getTime = () => {
      const time = Date.parse(deadline) - Date.now();    
        
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      const interval = setInterval(() => getTime(deadline), 1000);
  
      return () => clearInterval(interval);
    }, []);
  return (
    <div className='relative md:w-4/5 mb-5 mx-auto'>
    <div className='absolute left-7 md:left-48 top-1 md:top-5 p-4 rounded-lg bg-green-white rounded-b-lg'>
        <h1 className=' text-center text-[24px] font-[700] mb-3'>Countdown to the Event</h1>
        <div>
        <div className="flex justify-around" role="timer">
    <div className="col-4">
      <div className=" ">
      {/* {days < 10 ? "0" + days : days} */}
        <p className=" border rounded-lg bg-[#FFFCF5] text-green-p border-green-p p-2 text-center " id="day">{days}</p>
        <span className=" text-gray-500 text-center  text-xs">Days</span>
      </div>
    </div>
    <div className="col-4">
      <div className="box">
      {/* {hours < 10 ? "0" + hours : hours} */}
        <p className="  border rounded-lg bg-[#FFFCF5] text-green-p border-green-p p-2 text-center" id="hour">{hours}</p>
        <span className=" text-gray-500 text-center text-xs">Hours</span>
      </div>
    </div>
    <div className="col-4">
    {/* {minutes < 10 ? "0" + minutes : minutes} */}
      <div className="box">
        <p className="  border rounded-lg bg-[#FFFCF5] text-green-p border-green-p p-2 text-center" id="minute">{minutes}</p>
        <span className=" text-gray-500 text-center text-xs">Min</span>
      </div>
    </div>
    <div className="col-4">
    {/* {seconds < 10 ? "0" + seconds : seconds} */}
      <div className="box">
        <p className=" border rounded-lg bg-[#FFFCF5] text-green-p border-green-p p-2 text-center" id="second">{seconds}</p>
        <span className=" text-gray-500 text-center text-xs">Sec</span>
      </div>
    </div>
  </div>
        </div>
    </div>
    </div>
  )
}

export default Timer