import React from 'react'
import Icon from '../../images/Vector.png'
function ServiceComponent({header, sub}) {
  return (
    <div className=' flex gap-2 my-3 wow animate__animated animate__zoomIn'>
        <div className='flex-none'>
            <img src={Icon} className=' pt-1'  alt='icon'/>
        </div>
        <div className='grow  pl-2'>
            <h1 className=' text-white text-[24px] font-[700] mb-2'>{header}</h1>
            <p className=' text-white'>{sub}</p>
        </div>
    </div>
  )
}

export default ServiceComponent