import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Forward from '../../images/ff.png'
import Back from '../../images/back.png'
import One from '../../images/1.jpeg'
import Two from '../../images/2.jpeg'
import Three from '../../images/3.jpeg'
import Four from '../../images/4.jpeg'
import Five from '../../images/5.jpeg'
import Six from '../../images/6.jpeg'
import Seven from '../../images/7.jpeg'
import Eight from '../../images/8.jpeg'
// import Fosa from '../../images/fosa.jpeg'


import PastEventCom from "./PastEventCom";

function SampleNextArrow(props) {
  return (
    <div 
    />
  );
}

function SamplePrevArrow(props) {
  return (
    <div
    />
  );
}

export default class PastEvent extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
  
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    
    render() {
      const settings = {
        
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
      };

      return (
        <div id="pastevent" className=" p-2 bg-[#F8F8F8] md:p-10">
        <div className=" flex justify-between">
      <h1 className=" text-[30px] pt-2 md:pt-0  md:text-[48px] font-[700] text-black wow animate__animated animate__slideInLeft">Past Events</h1>
      <div className="flex">
            <button className="p-2" onClick={this.previous}>
            <img src={Back} alt='Back'/>
          </button>
          <button className="p-2" onClick={this.next}>
          <img src={Forward} alt='Back'/>
          </button>
        </div> 
      </div>
          <Slider ref={c => (this.slider = c)} {...settings}>
          {/* <div key={0}>
             <PastEventCom
                img={Fosa}
                header='FOSA Awards 2022 '
                sub='FOSA is an annual event that celebrates students'
             />
            </div> */}
            <div key={1}>
             <PastEventCom
                img={One}
                header='Welcome Back To School Fiesta '
                sub='A good start back to school with a high vibe type party'
             />
            </div>
            <div key={2}>
            <PastEventCom
                img={Two}
                header='FUNAAB Outstanding Youth Awards'
                sub='Parties at the pool are relaxed cruises for a comprehensive tour of adventure.'
             />
            </div>
            <div key={3}>
            <PastEventCom
                img={Three}
                header="Jasi's House Party"
                sub='The Gat me lit house party as promises, was the best after exam experience ever.'
             />
            </div>
            <div key={4}>
            <PastEventCom
                img={Four}
                header='Fiesta De Matriculation'
                sub='A welcome party for the freshmen on campus. We made them feel among and initiated into the system of greatness'
             />
            </div>
            <div key={5}>
            <PastEventCom
                img={Five}
                header='X1 Pool Party'
                sub='Parties at the pool are relaxed cruises for a comprehensive tour of adventure.'
             />
            </div>
            <div key={6}>
            <PastEventCom
                img={Six}
                header='Joor Party with DJ Kaywise'
                sub='We brought the disc jockeying energy alive right in front of our beautiful audience.'
             />
            </div>
            <div key={7}>
            <PastEventCom
                img={Seven}
                header='The Origin House Party I'
                sub="Staying true to our roots, we made you feel original just because that's what Origin makes you become, at the comfort of your house"
             />
            </div>
            <div key={8}>
            <PastEventCom
                img={Eight}
                header='The Origin House Party II'
                sub='One good turn deserves another. You all wanted more of it, and we brought the originality back to your doorsteps.'
             />
            </div>
          </Slider>
        
        </div>
      );
    }
  }
