import React from 'react'
import ServiceComponent from './ServicesComponent'

function Services() {
  return (
    <div id='services' className=' bg-primary-color3 p-5 md:p-16 md:pt-48'>
        <h1 className=' text-center text-white text-[48px] mb-4 font-[700] wow animate__animated animate__slideInLeft'>Our Services</h1>
        <div className=' grid md:grid-cols-3 gap-4'>
            <ServiceComponent
                header='Event Management'
                sub='Our team of program managers is fully dedicated to ensuring the successful execution of pre-event and post-event activities such as Logistics, finances, and miscellaneous.'
            />
            <ServiceComponent
                header='Brand Partnership'
                sub='We are open to collaborations with suitable brands whose mission and vision statements align with ours for event planning, branding, and form of promotions.'
            />
            <ServiceComponent
                header='Branding & Promotion'
                sub='We create and showcase your unique brand suitable identity to your target audience. Our hands-on experience is enough to ensure you get the best delivery.'
            />
            <ServiceComponent
                header='Content Creation'
                sub="Our team of graphics designers and content writers are ever ready to create tailored and catchy content that's sure to leave your audience open-mouthed in amazement."
            />
            <ServiceComponent
                header='Social Media Strategy'
                sub="We draft the best fit strategy for your brand's social media pages to drive necessary engagements and boost your brand's online presence and visibility."
            />
        </div>
    </div>
  )
}

export default Services