import React from 'react'

function Aboutus() {
  return (
    <div id='aboutus' className=' mt-5 p-5 md:p-10'>
        <div className=' grid md:grid-cols-2 gap-4'>
            <div className=' md:mt-16 flex flex-row justify-center'>
                <div className=' wow animate__animated animate__slideInLeft'>
                    <h1 className=' text-black text-[48px] mb-4 font-[700]'>About Us</h1>
                            <p className=' text-[18px]'>Jasline Entertainment <br></br>
                            is the life of the party — we are the party.</p>
                </div>
            </div>
            <div className=' flex gap-4 md:gap-6'>
            <div className=' flex flex-col gap-3 md:gap-4'>
                <div className=' p-5 bg-primary-color1 rounded-lg wow animate__animated animate__zoomIn '>
                    <p className=''>Jasline Entertainment is a brand dedicated to ensuring the success of your events. We offer actionable services such as planning, management, branding, and promotion.</p>
                </div>
                <div className='   p-5 h-full bg-primary-color2 wow animate__animated animate__zoomIn rounded-lg  '>
                    <p className='  '>Currently, we are working with two active and incredible labels: Chippy Records and Shadykarz.</p>
                </div>
            </div>
            <div className=' flex flex-col gap-3 md:gap-4'>
                <div className='    p-5 bg-primary-color2 wow animate__animated animate__zoomIn rounded-lg  '>
                    <p className='  '>With over four years and five major events under the belt, we command one of the most robust audiences and resources in the community.</p>
                </div>
                <div className='p-5 bg-primary-color1 wow animate__animated animate__zoomIn rounded-lg  '>
                    <p className=''>Jasline Entertainment also manages various entertainment labels. We have worked with different entertainment goal-oriented brands like Meggyobena and other labels.</p>
                </div>
            </div>
            </div>
          
        </div>
    </div>
  )
}

export default Aboutus