import React from 'react'
import Fresh from '../../images/academicicon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 md:pt-16 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={Fresh} className=' mx-auto' alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-[black] font-[700] mb-2'>Academics</h1>
                
                <p className=' text-[14px]  md:text-2xl font-[400]'>The BEST STUDENTS ACADEMIC Awards are given to individuals who have demonstrated outstanding academic achievement during the school sessions). These awards recognize
-the hard work, dedication and sheer commitment to excellence these students have shown in their studies.<br></br><br></br>
Some of these students may also have had other significant impacts on the environment and their peers for collective academic growth. Finally, the scope of this category only covers the school's active students in any of the 10 colleges.</p>
            </div>
            
        </div>
    </div>
  )
}

export default Content