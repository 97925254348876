import React, {useEffect, useState} from 'react'
import getServices from '../../Services/get-services';
import Button from '../SelectValue/Button';

import swal from 'sweetalert';
import Loading from '../SelectValue/Loadingdropdown';
import ReactGA from 'react-ga'
import VoteComp from '../VoteComp';
import postService from '../../Services/post-services';
function Vote() {
    const[ nominees, setNominees] = useState([])
    const[ nomineesFemale, setNomineesFemale] = useState([])
    const[ nomineesNext, setNomineesNext] = useState([])
    const[ nomineesFemaleBestNew, setNomineesFemaleBestNew] = useState([])
    const[ nomineesMaleBestNew, setNomineesMaleBestNew] = useState([])
    const [loading, setLoading] = useState(false)
    const [maleRookieValues, setMaleRookieValues] = useState('');
    const [femaleRookieValues, setFemaleRookieValue] = useState('')
    const [femaleBestNew, setFemaleBestNew] = useState('')
    const [maleBestNew, setMaleBestNew] = useState('')
  const [ nextRated, setNextRated] = useState('')
    const data = {
        votes: [
          {
            nominee_id: maleRookieValues,
            category_id: 68
          },
          {
            nominee_id: femaleRookieValues,
            category_id: 69
          },
          {
            nominee_id: nextRated,
            category_id: 50
          },
          {
            nominee_id: maleBestNew,
            category_id: 66
          },
          {
            nominee_id: femaleBestNew,
            category_id: 67
          }
        ]
      };
    
    const handleSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
            console.log(maleRookieValues)
            ReactGA.event({'category': 'freshstudent', 'action': 'Submitvote','label': 'vote o' })
            data.votes = data.votes.filter((vote) =>  vote.nominee_id !== '')
          console.log(data)
      
      postService.votesNomines(data).then(
          (response) => {
              setLoading(false)
              console.log(response.data)
              swal("Voting completed Successfully")
                .then((value) => {
                  window.location.reload()
                });
             
            },
            (error) => {
              setLoading(false)
                return  swal('Voting failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )


    }
    useEffect(() =>{
        getServices.getNominees(5).then(
          (response) => {
            setNominees(response.data.nominies['ROOKIE OF THE YEAR MALE']);
            setNomineesFemale(response.data.nominies['ROOKIE OF THE YEAR FEMALE']);
            
            setNomineesFemaleBestNew(response.data.nominies['BEST NEW STUDENTS FEMALE'])
            setNomineesMaleBestNew(response.data.nominies['BEST NEW STUDENTS MALE'])
            console.log(response.data)
            
          },
          (error) => {
           
    
              setNominees([]);
          }
        )
        getServices.getNominees(2).then(
          (response) => {
           
            setNomineesNext(response.data.nominies["NEXT RATED ACT"]);
            
          },
          (error) => {
           
    
            
          }
        )
       
      }, [])
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    <Loading
        open={loading}
    />
    <h1 className=' text-4xl text-white text-center text-[700] mb-4'>Vote Now</h1>
    <form onSubmit={handleSubmit}>
    <div className=' grid md:grid-cols-3 gap-4'>
      <VoteComp
        num='1'
        title='Male Rookie Of The Year'
        nominees={nominees}
            value={maleRookieValues}
            onChange={setMaleRookieValues}
      />
      <VoteComp
        num='2'
        title='Female Rookie Of The Year'
        nominees={nomineesFemale}
            value={femaleRookieValues}
            onChange={setFemaleRookieValue}
      />
        {/* <VoteComp
          num='3'
          title='Next Rated Artiste of the year'
          nominees={nomineesNext}
            value={nextRated}
            onChange={setNextRated}
        /> */}
        
        <VoteComp
          num='3'
          title='Female Best New Student of the Year'
          // //sponsor=''
          nominees={nomineesFemaleBestNew}
            value={femaleBestNew}
            onChange={setFemaleBestNew}
        />
       <VoteComp
          num='4'
          title='Male Best New Student of the Year'
          // // //sponsor='- //sponsored by Agba Cybernet & Edu-Consult'
          nominees={nomineesMaleBestNew}
            value={maleBestNew}
            onChange={setMaleBestNew}
        />
       
    </div>
    <div className=' mt-6 flex gap-1'>
    <Button
        name='Submit'
    />
    
    </div>
   
    </form>
   
    </div>
  )
}

export default Vote