import React from 'react'

function Topsec() {
  return (
    <div className=' team-bg'>
        <div className='grid h-[80vh] place-items-center'>
        <div className='bg-primary-color5 p-3 m-3 md:p-7 rounded-lg wow animate__animated animate__slideInLeft'>
            <h1 className=' text-white text-2xl md:text-5xl font-[700]  text-center  '>Meet our Team Members</h1>
           
        </div>
        </div>
    </div>
  )
}

export default Topsec