import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import S1 from '../../images/s1.png'
import S2 from '../../images/s22.png'


class Sponsors extends Component{
    render(){
      const settings = {
       
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              autoplay: true,
              speed: 5000,
              autoplaySpeed: 5000,
              cssEase: "linear",
            }
          },
          {
            breakpoint: 600,
            settings: {
              infinite: true,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
              speed: 5000,
              autoplaySpeed: 5000,
              cssEase: "linear",
            }
          },
          {
            breakpoint: 480,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 2,
              autoplay: true,
              speed: 5000,
              autoplaySpeed: 5000,
              cssEase: "linear",
            }
          }
        ]
      };
      return(
        <div className=" p-6 md:p-16 mb-8 mt-24 md:mt-10">
          <h1 className=' text-black text-5xl font-[700] text-center mt-5 mb-10 '>Sponsors</h1>
          <Slider {...settings}>
            <div className="container">
              <img alt='logo' className=' m-6' src={S1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-6' width='198' height='128' src={S2}/>
            </div>
            
            <div className="container">
              <img alt='logo' className=' m-6' src={S1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-6' width='198' height='128' src={S2}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-6' src={S1} />
            </div>
            <div className="container">
              <img alt='logo' className=' m-6' width='198' height='128' src={S2}/>
            </div>
          </Slider>
        </div>
      );
    }
  }
  
  export default Sponsors;