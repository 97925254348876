import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function VoteButton({nominees,value, onChange }) {

  

  return (
    <div className=' mt-3 p-2 text-white'>
        <FormControl>
     
     <RadioGroup
       aria-labelledby="demo-controlled-radio-buttons-group"
       name="controlled-radio-buttons-group"
       value={value}
       
       onChange={(e) => onChange(e.target.value)}
     >
       {nominees.map((e) =>{
           return <div key={e.id}>
           <FormControlLabel value={e.id} control={<Radio required={true}
  sx={{
    '&, &.Mui-checked': {
      color: '#27AE61',
    },
  }} />} label={e.name} />
           </div>
       })}
      
       
     </RadioGroup>
   </FormControl>
    </div>
    
  );
}