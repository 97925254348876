import React from 'react'
import Fresh from '../../images/academicicon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 md:pt-16 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={Fresh} className=' mx-auto' alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-[black] font-[700] mb-2'>Entrepreneur</h1>
                
                <p className=' text-[14px]  md:text-xl font-[400]'>The ENTREPRENEURS CATEGORY award is designed to recognize and celebrate the exceptional achievements of students who have demonstrated outstanding entrepreneurial skills and ingenuity in their academic pursuits. This award recognizes students who have taken the initiative to develop and implement innovative business ideas, resulting in positive outcomes for their communities and themselves, of course.<br></br><br></br>
The recipients of this award are recognized for their ability to think creatively, solve problems, and take calculated risks to achieve their goals. They are also commended for their strong leadership skills, their ability to manage time and resources effectively, and their commitment to excellence in all aspects of their work.<br></br><br></br>
This award is a testament to the dedication and hard work of these students and serves as a recognition of their contributions to the school's field of entrepreneurship. It is a celebration of their success, and a recognition of their potential to become the next generation of business leaders.</p>
            </div>
            
        </div>
    </div>
  )
}

export default Content