import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlinedIcon  from '@mui/icons-material/ExpandCircleDownOutlined';

function FaqContent() {
  return (
    <div className=' mb-9  mt-10 px-6 md:px-16'>
    <h1 className=' text-3xl font-[700] mb-4 text-center'>Frequently Asked Questions</h1>
      <Accordion
      elevation={0}
       className=' mb-3 border-none' >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon  fontSize="large" className='border-[#00BC67]  text-[#00BC67] bg-transparent' />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h1 className=' md:text-2xl  text-[16px]  text-black font-[700]'>Is FOSA different from FOYA?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          No, it is not. FOSA is the evidence of EVOLUTION our biggest award outfit,
FOYA, is going through. It is the rebranded FOYA.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
          elevation={0}
          sx={{
            '&:before': {
                display: 'none',
            }
        }}
          className=' mb-3 border-none'
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon fontSize="large"  className='border-[#00BC67]  text-[#00BC67] bg-transparent' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
         <h1 className=' md:text-2xl text-[16px]  text-black font-[700]'>What does FOSA stand for?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          FOSA is an acronym for FUNAAB Outstanding Students' Awards.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
          elevation={0}
          className=' mb-3 border-none'
          sx={{
            '&:before': {
                display: 'none',
            }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon fontSize="large"  className='border-[#00BC67] text-[#00BC67] bg-transparent' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h1 className=' md:text-2xl text-[16px]   text-black font-[700]'>Do I need to pay?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          No, you don't. All award categories are merited with the highest number of votes from the public engagers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
      elevation={0}
      sx={{
            '&:before': {
                display: 'none',
            }
        }}
       className=' mb-3 border-none' >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon fontSize="large"  className='border-[#00BC67]  text-[#00BC67] bg-transparent' />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h1 className=' md:text-2xl text-[16px]   text-black font-[700]'>How do I make my nominations?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Follow the available format and send your nominations to the contacts on the flier via WhatsApp.<br></br>
FORMAT: Name, Dept, Level and Fitting Categories)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
          elevation={0}
          sx={{
            '&:before': {
                display: 'none',
            }
        }}
          className=' mb-3 border-none'
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon fontSize="large"  className='border-[#00BC67]  text-[#00BC67] bg-transparent' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
         <h1 className=' md:text-2xl text-[16px]  text-black font-[700]'>Can I nominate myself?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Yes, you can. As long as you deem yourself fit for any category -give yourself the chance to be appreciated duly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
          elevation={0}
          className=' mb-3 border-none'
          sx={{
            '&:before': {
                display: 'none',
            }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownOutlinedIcon fontSize="large"  className='border-[#00BC67] text-[#00BC67] bg-transparent' />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h1 className=' md:text-2xl text-[16px]  text-black font-[700]'>How will voting be done?</h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          All voting happens on our website. Just visit the publicised link to go vote for your FAVES and recognise their EVOLUTION appropriately.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FaqContent