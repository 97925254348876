import React from 'react'

function Topsec() {
  return (
    <div className=' fosa-bg '>
        <div className='grid h-[80vh] place-items-center'>
            <div className='bg-primary-color5 p-3 m-3 md:p-7 rounded-lg'>
                <h1 className=' text-white text-2xl md:text-5xl  text-center font-[700]'>Welcome to </h1>
                <h1 className=' text-white text-2xl md:text-5xl  font-[700] text-center'>  FOSA Voting Page</h1>
            </div>
        </div>
    </div>
  )
}

export default Topsec