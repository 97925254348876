import React from 'react'

function Button({name, onClick}) {
  return (
    <>
    <button 
      className=' bg-green-p  px-4 py-2 border-none rounded-lg text-white'
      onClick={onClick}
      type='submit' 
     >{name}</button>   
    </>
  )
}

export default Button