import React, {useEffect, useState} from 'react'
import getServices from '../../Services/get-services';
import Button from '../SelectValue/Button';
import swal from 'sweetalert';
import Loading from '../SelectValue/Loadingdropdown';
import ReactGA from 'react-ga'
import VoteComp from '../VoteComp';
import postService from '../../Services/post-services';
function Vote() {
      const [submit, setSubmit] = useState(false)
    const[ nomineesPopularMale, setNomineesPopularMale] = useState([])
    const [loading, setLoading] = useState(false)
    const [mostPopularMaleValue, setMostPopularMaleValue] = useState('');
    const [mostPopularFemaleValue, setMostPopularFemaleValue] = useState('');
    const [sportManValue, setSportManValue] = useState('')
    const [sportWomanVale, setSportWomanValue] = useState('')
    const [fashionableMaleValue, setFashionableMale] = useState('')
    const [fashionableFemaleValue, setFashionableFemale] = useState('')
    const [mrPerValue,setMrPerValue] = useState('')
    const [missPerValue, setMissPerValue] = useState('')
    const [gentleValue, setGentleValue] = useState('')
    const [reservedMaleValue, setReservedMaleValue] = useState('')
    const [reservedFemaleValue, setReservedFemaleValue] = useState('')
    const [bestDressMaleValue, setBestDressMaleValue] = useState('')
    const [bestDressFemaleValue, setBestDressFemaleValue] = useState('')
    const [petiteValue, setPititeValue] = useState('')
    const [big, setBig] = useState('')
    const [ebony, setEbony] = useState('')
    const[beautiful, setBeautiful] = useState('')
    const [handsome, setHandsome] = useState('')
    const [manYear, setManYear] = useState("")
    const [expansiveMale, setExpansiveMale] = useState('')
    const [expansiveFemale, setExpansiveFemale] = useState('')
    const [moneyBagMale, setMoneyBagmale] = useState('')
    const [moneyBagFemale, setmoneyBagFemale] = useState('')
    const[unsung, setUnsung] = useState('')
    const [bestClique, setBestClique]= useState('')
    const [soughtMale, setSoughtMale]= useState('')
    const [soughtFemale, setSoughtFemale]= useState('')
    const [influencerMale, setInfluencerMale]= useState('')
    const [influencerFemale, setInfluencerFemale]= useState('')
    const [sophisticatedMale, setSophisticatedMale] = useState('')
    const [sophisticatedFemale, setSophisticatedFemale] = useState('')
    const[modalMale, setModalMale]= useState('')
    const[modalFemale, setModalFemale]= useState('')
    const [revelation, setRevelation] = useState('')
    const [hourGlass, setHourGlass] = useState('')
    const [creative, setCreative]= useState('')
    const [photogenic, setPhotogenic] = useState('')
    const [golden, setGolden] = useState('')
    const [yagi, setYagi] = useState('')
    const[useSocial, setUseSocial] = useState('')
    const [nomineesPopularFemale, setNominessPopularFemale] = useState([])
    const [nomineesSportWoman, setNominessSportWoman] = useState([])
    const [nomineesSportMan, setNominessSportMan] = useState([])
    const [nomineesFashionableMan, setNominessFashionableMan] = useState([])
    const [nomineesFashionableWoman, setNominessFashionableWoman] = useState([])
    const [nomineesMrPersonality, setNominessMrPersonality] = useState([])
    const [nomineesMissPersonality, setNominessMissPersonality] = useState([])
    const [nomineesGentlemen, setNominessGentlemen] = useState([])
    const [nomineesMostReserveMale, setNominessMostReserveMale] = useState([])
    const [nomineesMostReserveFemale, setNominessMostReserveFemale] = useState([])
    const [nomineesMaleBestDress, setNominessMaleBestDress] = useState([])
    const [nomineesFemaleBestDress, setNominessFemaleBestDress] = useState([])
    const [nomineesMisPetite, setNominessMisPetite] = useState([])
    const [nomineesBig, setNominessBig] = useState([])
    const [nomineesEbony, setNominessEbony] = useState([])
    const [nomineesBeautiful, setNominessBeautiful] = useState([])
    const [nomineesHandsome, setNominessHandsome] = useState([])
    const [nomineesManYear, setNominessManYear] = useState([])
    const [nomineesExpansiveMan, setNominessExpansiveMan] = useState([])
    const [nomineesExpansiveWoman, setNominessExpansiveWoman] = useState([])
    const [nomineesMoneyBagMan, setNominessMoneyBagMan] = useState([])
    const [nomineesMoneyFemale, setNominessMoneyFemale] = useState([])
    const [nomineesUnsungHero, setNominessUnsungHero] = useState([])
    const [nomineesBestClique, setNominessBestClique] = useState([])
    const [nomineesSoughtAfterMale, setNominessSoughtAfterMale] = useState([])
    const [nomineesSoughtAfterFemale, setNominessSoughtAfterFemale] = useState([])
    const [nomineesInfluentailMale, setNominessInfluentailMale] = useState([])
    const [nomineesInfluentialFemale, setNominessInfluentialFemale] = useState([])
    const [nomineesSophisticatedMale, setNominessSophisticatedMale] = useState([])
    const [nomineesSophisticatedFemale, setNominessSophisticatedFemale] = useState([])
    const [nomineesMaleModel, setNominessMaleModel] = useState([])
    const [nomineesFemaleModel, setNominessFemaleModel] = useState([])
    const [nomineesRevelation, setNominessRevelation] = useState([])
    const [nomineesHourGlass, setNominessHourGlass] = useState([])
    const [nomineesCreativeStudent, setNominessCreativeStudent] = useState([])
    const [nomineesMissPhoto, setNominessMissPhoto] = useState([])
    const [nomineesGoldenBoy, setNominessGoldenBoy] = useState([])
    const [nomineesYagi, setNominessYagi] = useState([])
    const [nomineesSocialMedia, setNominessSocialMedia] = useState([])
    const data = {
        votes: [
          {
            nominee_id: mostPopularMaleValue,
            category_id: 6
          },
          {
            nominee_id: mostPopularFemaleValue,
            category_id: 7
          },
          {
            nominee_id: sportManValue,
            category_id: 8
          },
          {
            nominee_id:sportWomanVale,
            category_id: 9
          },
          {
            nominee_id: fashionableMaleValue,
            category_id: 10
          },
          {
            nominee_id: fashionableFemaleValue,
            category_id: 11
          },
          {
            nominee_id: mrPerValue,
            category_id: 12
          },
          {
            nominee_id: missPerValue,
            category_id: 13
          },
          {
            nominee_id: gentleValue,
            category_id: 14
          },
          {
            nominee_id: reservedMaleValue,
            category_id: 43
          },
          {
            nominee_id: bestDressMaleValue,
            category_id: 15
          },
          {
            nominee_id:bestDressFemaleValue,
            category_id: 16
          },
          {
            nominee_id: petiteValue,
            category_id: 17
          },
          {
            nominee_id: big,
            category_id: 18
          },
          {
            nominee_id: ebony,
            category_id: 19
          },
          {
            nominee_id: beautiful,
            category_id: 20
          },
          {
            nominee_id: handsome,
            category_id: 21
          },
          {
            nominee_id: manYear,
            category_id: 80
          },
          {
            nominee_id: expansiveMale,
            category_id: 22
          },
          {
            nominee_id:moneyBagMale,
            category_id: 24
          },
          {
            nominee_id: unsung,
            category_id: 27
          },
          {
            nominee_id: bestClique,
            category_id: 28
          },
          {
            nominee_id: soughtMale,
            category_id:37
          },
          {
            nominee_id: soughtFemale,
            category_id: 38
          },
          {
            nominee_id: influencerMale,
            category_id: 31
          },
          {
            nominee_id: sophisticatedMale,
            category_id: 33
          },
          {
            nominee_id: influencerFemale,
            category_id: 32
          },
          {
            nominee_id:sophisticatedFemale,
            category_id: 34
          },
          {
            nominee_id: modalMale,
            category_id: 35
          },
          // {
          //   nominee_id: modalFemale,
          //   category_id: 61
          // },
          {
            nominee_id: revelation,
            category_id: 41
          },
          {
            nominee_id: hourGlass,
            category_id: 42
          },
          {
            nominee_id: creative,
            category_id: 29
          },
          {
            nominee_id: expansiveFemale,
            category_id:23
          },
          {
            nominee_id: moneyBagFemale,
            category_id: 25
          },
          {
            nominee_id:photogenic,
            category_id: 36
          },
          {
            nominee_id: golden,
            category_id: 39
          },
          {
            nominee_id: yagi,
            category_id: 40
          },
          {
            nominee_id: reservedFemaleValue,
            category_id: 44
          },
          // {
          //   nominee_id: useSocial,
          //   category_id: 23
          // },
        ]
      };
    
    // const handleSubmit = (e) =>{
    //         e.preventDefault()
    //         setLoading(true)
    //         ReactGA.event({'category': 'fgeneral', 'action': 'Submitvote','label': 'vote o' })
    //         swal("Voting Closed. Winners would be announced on the 8th of Febuary")
    //         .then((value) => {
    //           window.location.reload()
    //         });
            
    // }

    const handleSubmit = (e) =>{
      e.preventDefault()
      setLoading(true)
      ReactGA.event({'category': 'fgeneral', 'action': 'Submitvote','label': 'vote o' })
      data.votes = data.votes.filter((vote) =>  vote.nominee_id !== '')
          console.log(data)
      
      postService.votesNomines(data).then(
          (response) => {
              setLoading(false)
              console.log(response.data)
              swal("Voting completed Successfully")
                .then((value) => {
                  window.location.reload()
                });
             
            },
            (error) => {
              setLoading(false)
                return  swal('Voting failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )

}
    useEffect(() =>{
      
        getServices.getNominees(4).then(
          (response) => {
            setNomineesPopularMale(response.data.nominies["MOST POPULAR MALE"]);
            setNominessPopularFemale(response.data.nominies["MOST POPULAR FEMALE"]);
            setNominessSportWoman(response.data.nominies["SPORTSWOMAN OF THE YEAR"]);
            setNominessSportMan(response.data.nominies["SPORTSMAN OF THE YEAR"]);
            setNominessFashionableMan(response.data.nominies["MOST FASHIONABLE MALE"]);
            setNominessFashionableWoman(response.data.nominies["MOST FASHIONABLE FEMALE"]);
            setNominessMrPersonality(response.data.nominies["MR PERSONALITY"]);
            setNominessMissPersonality(response.data.nominies["MISS PERSONALITY"]);
            setNominessGentlemen(response.data.nominies["PERFECT GENTLEMAN "]);
            setNominessMostReserveMale(response.data.nominies["MOST RESERVED MALE"]);
            setNominessMostReserveFemale(response.data.nominies["MOST RESERVED FEMALE"]);
            setNominessMaleBestDress(response.data.nominies["BEST DRESSED MALE"]);
            setNominessFemaleBestDress(response.data.nominies["BEST DRESSED FEMALE"]);
            setNominessMisPetite(response.data.nominies["MISS PETIT"]);
            setNominessBig(response.data.nominies["BIG BOLD AND BEAUTIFUL"]);
            setNominessBeautiful(response.data.nominies["MOST BEAUTIFUL"]);
            setNominessEbony(response.data.nominies["MISS EBONY"]);
            setNominessHandsome(response.data.nominies["MOST HANDSOME"]);
            setNominessManYear(response.data.nominies["MAN OF THE YEAR"]);
            setNominessExpansiveMan(response.data.nominies["MOST EXPENSIVE MALE"]);
            setNominessExpansiveWoman(response.data.nominies["MOST EXPENSIVE FEMALE"]);
            setNominessMoneyBagMan(response.data.nominies["MONEY BAG MALE"]);
            setNominessMoneyFemale(response.data.nominies["MONEY BAG FEMALE "]);
            setNominessUnsungHero(response.data.nominies["UNSUNG HERO"]);
            setNominessBestClique(response.data.nominies["BEST CLIQUE"]);
            setNominessSoughtAfterMale(response.data.nominies["SOUGHT AFTER MALE"]);
            setNominessSoughtAfterFemale(response.data.nominies["SOUGHT AFTER FEMALE"]);
            setNominessInfluentailMale(response.data.nominies["MOST INFLUENTIAL MALE"]);
            setNominessInfluentialFemale(response.data.nominies["MOST INFLUENTIAL FEMALE"]);
            setNominessSophisticatedFemale(response.data.nominies["MOST SOPHISTICATED FEMALE"]);
            setNominessSophisticatedMale(response.data.nominies["MOST SOPHISTICATED MALE"]);
            setNominessMaleModel(response.data.nominies["MODEL OF THE YEAR"]);
            setNominessFemaleModel(response.data.nominies["MODEL OF THE YEAR"]);
            setNominessRevelation(response.data.nominies["REVELATION OF THE YEAR"]);
            setNominessHourGlass(response.data.nominies["HOUR GLASS"]);
            setNominessCreativeStudent(response.data.nominies["POLITICAL ICON"]);
            setNominessMissPhoto(response.data.nominies["MISS PHOTOGENIC"]);
            setNominessGoldenBoy(response.data.nominies["GOLDEN BOY"]);
            setNominessYagi(response.data.nominies["YAGI YOUNG AND GETTING IT "]);
            // setNominessSocialMedia(response.data.nominies["Best Use of Social Media"]);
           
            console.log(response.data)
            
          },
          (error) => {
           
    
            setNomineesPopularMale([]);
          }
        )
       
      }, [])
      // POLITICAL ICON
      //	BEST HOC
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    <Loading
        open={loading}
    />
    <h1 className=' text-4xl text-white text-center text-[700] mb-9'>Vote Now</h1>
    <form onSubmit={handleSubmit}>
    <div className=' grid md:grid-cols-3 gap-4'>
        <VoteComp
          num='1'
          title='Most Popular Male -'
         // //sponsor='//sponsored by Cintinnati by Labongoes'
          nominees={nomineesPopularMale}
            value={mostPopularMaleValue}
            onChange={setMostPopularMaleValue}
        />
        <VoteComp
          num='2'
          title='Most Popular Female'
         // //sponsor=''
          nominees={nomineesPopularFemale}
            value={mostPopularFemaleValue}
            onChange={setMostPopularFemaleValue}
        />
       <VoteComp
          num='3'
          title='Sportsman of the Year'
         // //sponsor=''
          nominees={nomineesSportMan}
            value={sportManValue}
            onChange={setSportManValue}
        />
       

        </div>
       
        <div className=' grid md:grid-cols-3 gap-4'>
        <VoteComp
          num='4'
          title='Sportswoman of the Year - '
         // //sponsor='//sponsored by Aquam Water'
          nominees={nomineesSportWoman}
            value={sportWomanVale}
            onChange={setSportWomanValue}
        />
       <VoteComp
          num='5'
          title='Most Fashionable Male Student'
         // //sponsor=''
          nominees={nomineesFashionableMan}
            value={fashionableMaleValue}
            onChange={setFashionableMale}
        />
       <VoteComp
          num='6'
          title='Most Fashionable Female Student'
         // //sponsor=''
          nominees={nomineesFashionableWoman}
            value={fashionableFemaleValue}
            onChange={setFashionableFemale}
        />
       <VoteComp
          num='7'
          title='Mr Personality'
         // //sponsor='// //sponsored by Mika Footies'
          nominees={nomineesMrPersonality}
            value={mrPerValue}
            onChange={setMrPerValue}
        />
         <VoteComp
          num='8'
          title='Miss Personality'
         // //sponsor=''
          nominees={nomineesMissPersonality}
            value={missPerValue}
            onChange={setMissPerValue}
        />
         <VoteComp
          num='9'
          title='Perfect Gentleman'
         // //sponsor=''
          nominees={nomineesGentlemen}
            value={gentleValue}
            onChange={setGentleValue}
        />
        <VoteComp
          num='10'
          title='Most Reserved Male Student of the Year'
         // //sponsor=''
          nominees={nomineesMostReserveMale}
            value={reservedMaleValue}
            onChange={setReservedMaleValue}
        />
        <VoteComp
          num='11'
          title='Most Reserved Female Student of the Year'
         // //sponsor=''
          nominees={nomineesMostReserveFemale}
            value={reservedFemaleValue}
            onChange={setReservedFemaleValue}
        />
        <VoteComp
          num='12'
          title='Male Best-Dressed Student of the Year - '
         // //sponsor='//sponsored by Legendary Wash'
          nominees={nomineesMaleBestDress}
            value={bestDressMaleValue}
            onChange={setBestDressMaleValue}
        />
       <VoteComp
          num='13'
          title='Female Best-Dressed Student of the Year - '
         // //sponsor='//sponsored by Legendary Wash'
          nominees={nomineesFemaleBestDress}
            value={bestDressFemaleValue}
            onChange={setBestDressFemaleValue}
        />
        <VoteComp
          num='14'
          title='Miss Petite'
          //sponsor='//sponsored by Buckingham Mall'
          nominees={nomineesMisPetite}
            value={petiteValue}
            onChange={setPititeValue}
        />
        <VoteComp
          num='15'
          title='Big, Bold and Beautiful'
          nominees={nomineesBig}
            value={big}
            onChange={setBig}
        />
         <VoteComp
          num='16'
          title='Miss Ebony'
          nominees={nomineesEbony}
            value={ebony}
            onChange={setEbony}
        />
         <VoteComp
          num='17'
          title='Most Beautiful -'
        //sponsor='//sponsored by Chummie’s place'
          nominees={nomineesBeautiful}
            value={beautiful}
            onChange={setBeautiful}
        />
        
        <VoteComp
          num='18'
          title='Most Handsome -'
        //sponsor='//sponsored by Buckingham Mall'
          nominees={nomineesHandsome}
            value={handsome}
            onChange={setHandsome}
        />
      
      <VoteComp
          num='19'
          title='Man of the Year'
          //sponsor={"//sponsored by Manuel Enterprises"}
          nominees={nomineesManYear}
            value={manYear}
            onChange={setManYear}
        />
      <VoteComp
          num='20'
          title='Most Expensive Male'
          nominees={nomineesExpansiveMan}
            value={expansiveMale}
            onChange={setExpansiveMale}
        />
       <VoteComp
          num='21'
          title='Most Expensive Female'
          nominees={nomineesExpansiveWoman}
            value={expansiveFemale}
            onChange={setExpansiveFemale}
        />
       <VoteComp
          num='22'
          title='Money Bag of the Year Male -'
          //sponsor={'//sponsored by Whateva brand'}
          nominees={nomineesMoneyBagMan}
            value={moneyBagMale}
            onChange={setMoneyBagmale}
        />
        <VoteComp
          num='23'
          title='Money Bag of the Year Female -'
          //sponsor={'//sponsored by Whateva brand'}
          nominees={nomineesMoneyFemale}
            value={moneyBagFemale}
            onChange={setmoneyBagFemale}
        />
        <VoteComp
          num='24'
          title='Unsung Hero'
          nominees={nomineesUnsungHero}
            value={unsung}
            onChange={setUnsung}
        />
         <VoteComp
          num='25'
          title='Best Clique'
          nominees={nomineesBestClique}
            value={bestClique}
            onChange={setBestClique}
        />
        <VoteComp
          num='26'
          title='Male Most Sought After Student of the Year'
          //sponsor={"//sponsored by Brymex Gadget"}
          nominees={nomineesSoughtAfterMale}
            value={soughtMale}
            onChange={setSoughtMale}
        />
       <VoteComp
          num='27'
          title='Female Most Sought After Student of the Year -'
          //sponsor={"//sponsored by Foodelo"}
          nominees={nomineesSoughtAfterFemale}
            value={soughtFemale}
            onChange={setSoughtFemale}
        />
      <VoteComp
          num='28'
          title='Male Most Influential Student of the Year -'
          //sponsor={'//sponsored by Whateva brand'}
          nominees={nomineesInfluentailMale}
            value={influencerMale}
            onChange={setInfluencerMale}
        />
       <VoteComp
          num='29'
          title='Female Most Influential Student of the Year -'
          //sponsor={'//sponsored by Whateva brand'}
          nominees={nomineesInfluentialFemale}
            value={influencerFemale}
            onChange={setInfluencerFemale}
        />
       <VoteComp
          num='30'
          title='Male Most Sophisticated Student of the Year'
         // //sponsor='//sponsored by Unboxed Luxury'
          nominees={nomineesSophisticatedMale}
            value={sophisticatedMale}
            onChange={setSophisticatedMale}
        />
         <VoteComp
          num='31'
          title='Female Most Sophisticated Student of the Year'
          nominees={nomineesSophisticatedFemale}
            value={sophisticatedFemale}
            onChange={setSophisticatedFemale}
        />
         <VoteComp
          num='32'
          title='Model of the Year'
          nominees={nomineesMaleModel}
            value={modalMale}
            onChange={setModalMale}
        />
         {/* <VoteComp
          num='33'
          title='Female Model of the Year'
          nominees={nomineesFemaleModel}
            value={modalFemale}
            onChange={setModalFemale}
        /> */}
        <VoteComp
          num='33'
          title='The Revelation of the Year'
          //sponsor={"//sponsored by Semmy Electronics"}
          nominees={nomineesRevelation}
            value={revelation}
            onChange={setRevelation}
        />
        <VoteComp
          num='34'
          title='Hourglass of the Year -'
         // //sponsor='//sponsored by Sneak Peek Luxury'
          nominees={nomineesHourGlass}
            value={hourGlass}
            onChange={setHourGlass}
        />
        <VoteComp
          num='35'
          title='Political Icon'
         // //sponsor='//sponsored by Only1pedro INC'
          nominees={nomineesCreativeStudent}
            value={creative}
            onChange={setCreative}
        />
         <VoteComp
          num='36'
          title='Miss Photogenic '
         // //sponsor=''
          nominees={nomineesMissPhoto}
            value={photogenic}
            onChange={setPhotogenic}
        />
        <VoteComp
          num='37'
          title='Golden Boy'
         // //sponsor=''
          nominees={nomineesGoldenBoy}
            value={golden}
            onChange={setGolden}
        />
        <VoteComp
          num='38'
          title='YAGI(Young and Getting It)'
         // //sponsor=''
          nominees={nomineesYagi}
            value={yagi}
            onChange={setYagi}
        />
       {/* <VoteComp
          num='40'
          title='Best Use of Social Media'
         // //sponsor=''
          nominees={nomineesSocialMedia}
            value={useSocial}
            onChange={setUseSocial}
        /> */}
        
    </div>
    <div className=' mt-6 flex gap-1'>
    <Button
        name='Submit'
    />
    
    </div>
   
    </form>
   
    </div>
  )
}

export default Vote