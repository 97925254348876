import React from 'react'

function ValueComponent({img, header, sub}) {
  return (
    <div className=' p-3 border m-2 mb-3 border-orange-p rounded-lg bg-[#FFFCF5] wow animate__animated animate__zoomIn'>
        <div className=' flex gap-3'>
            <div>
                <img src={img} alt='Imagee'/>

            </div>
            <div>
                <h1 className='  font-[700] text-2xl'>{header}</h1>
                <p className=''>{sub}</p>
            </div>
        </div>
    </div>
  )
}

export default ValueComponent