import React from 'react'

function SecondButton({name, onClick}) {
  return (
    <>
    <button 
     className='  border   bg-primary-color2   px-2 md:py-2 py-2 rounded-lg text-black'
     onClick={onClick}
     type='submit'
    >{name}</button>   
   </>
  )
}

export default SecondButton