import React, {useEffect, useState} from 'react'
import getServices from '../../Services/get-services';
import Button from '../SelectValue/Button';

import swal from 'sweetalert';
import Loading from '../SelectValue/Loadingdropdown';
import VoteButton from '../SelectValue/Votebutton';
import ReactGA from 'react-ga'
import VoteComp from '../VoteComp';
import postService from '../../Services/post-services';
function Vote() {
    const[ nomineesMaleMostEnterprising, setNomineesMaleMostEnterprising] = useState([])
    const[ nomineesFemaleMostEnterprising, setNomineesFemaleMostEnterprising] = useState([])
    const[ nomineesFemaleMostPromisingEnter, setNomineesFemaleMostPromisingEnter] = useState([])
    const[ nomineesFashionDesign, setNomineesFashionDesign] = useState([])
    const[ nomineesPhotographer, setNomineesPhotographer] = useState([])
    const[ nomineesBrandInflu, setNomineesBrandInflu] = useState([])
    const[ nomineesCeo, setNomineesCeo] = useState([])
    const[ nomineesMakeup, setNomineesMakeup] = useState([])
    const[ nomineesPr, setNomineesPr] = useState([])
    const[ nomineesMaleBestNew, setNomineesMaleBestNew] = useState([])
    const[ nomineesMostPromising, setNomineesMostPromising] = useState([])

    const [nomineesFashion, setNomineesFashion] = useState([])
    const [loading, setLoading] = useState(false)
    const [maleMostEnterprising, setMaleMostEnterprising] = useState('');
    const [femaleMostEnterprising, setFemaleMostEnterprising] = useState('')
    const [entrepreneurialBrand, setEntrepreneurialBrand] = useState('')
    const [fashionDesign, setFashionDesign] = useState('')
    const [photographer, setPhotographer] = useState('')
    const [brandInflu, setBrandInflu] = useState('')
    const [ceo, setCeo] = useState('')
    const [fashion, setFashion] = useState('')
    const [makeup, setMakeup] = useState('')
    const [pr, setPr] = useState('')
     const [ maleBestNew, setMaleBestNew] = useState('')
     const [ mostPromising, setMostPromising] = useState('')
    const data = {
        votes: [
          {
            nominee_id: maleMostEnterprising,
            category_id: 58
          },
          {
            nominee_id: femaleMostEnterprising,
            category_id: 73
          },
          
          {
            nominee_id: entrepreneurialBrand,
            category_id: 25
          }
          ,
          {
            nominee_id: fashionDesign,
            category_id: 57
          }
          ,
          {
            nominee_id: photographer,
            category_id: 64
          }
          ,
          {
            nominee_id: brandInflu,
            category_id: 61
          }
          ,
          {
            nominee_id: ceo,
            category_id: 59
          }
          ,
          {
            nominee_id: makeup,
            category_id: 60
          }
          
         
          ,
          {
            nominee_id: pr,
            category_id: 54
          }
          ,
          {
            nominee_id: mostPromising,
            category_id: 62
          },
          {
            nominee_id: fashion,
            category_id: 74
          }
        ]
      };
    
    const handleSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
            ReactGA.event({'category': 'Entrepreneur', 'action': 'Submitvote','label': 'vote o' })
            data.votes = data.votes.filter((vote) =>  vote.nominee_id !== '')
            console.log(data)
        postService.votesNomines(data).then(
          (response) => {
              setLoading(false)
              console.log(response.data)
              swal("Voting completed Successfully")
                .then((value) => {
                  window.location.reload()
                });
             
            },
            (error) => {
              setLoading(false)
                return  swal('Voting failed')
                .then((value) => {
                  window.location.reload()
                });
              }
        )
    }
    useEffect(() =>{
        getServices.getNominees(3).then(
          (response) => {
            setNomineesMaleMostEnterprising(response.data.nominies['MOST ENTERPRISING STUDENT MALE']);
            setNomineesFemaleMostEnterprising(response.data.nominies['MOST ENTERPRISING STUDENT FEMALE']);
            setNomineesFemaleMostPromisingEnter(response.data.nominies['MOST PROMISING ENTERPRISING']);
            // setNomineesEntrepreneurialBrand(response.data.nominies['Entrepreneurial Brand of the Year']);
            setNomineesFashionDesign(response.data.nominies['FASHION DESIGNER']);
            setNomineesPhotographer(response.data.nominies['PHOTOGRAPHER OF THE YEAR']);
            setNomineesBrandInflu(response.data.nominies['STUDENT BRAND OF THE YEAR']);
            // setNomineesBestNew(response.data.nominies['Best New Brand of the Year']);
            setNomineesCeo(response.data.nominies['CEO OF THE YEAR']);
            setNomineesMakeup(response.data.nominies['MAKE UP ARTIST OF THE YAER ']);
            
            setNomineesPr(response.data.nominies['PR of the Year']);
            setNomineesMostPromising(response.data.nominies['MOST PROMISING ENTERPRISING']);
            setNomineesFashion(response.data.nominies['fashion brand of the year']);
            console.log(response.data)
            
          },
          (error) => {
           
    
              setNomineesMaleBestNew([]);
          }
        )
       
      }, [])
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    <Loading
        open={loading}
    />
    <h1 className=' text-4xl text-white text-center text-[700] mb-4'>Vote Now</h1>
    <form onSubmit={handleSubmit}>
    <div className=' grid md:grid-cols-3 gap-4'>
        <VoteComp
          num='1'
          title='Male Most Enterprising Student of the Year -'
          ////sponsor='////sponsored by Unified realty'
          nominees={nomineesMaleMostEnterprising}
            value={maleMostEnterprising}
            onChange={setMaleMostEnterprising}
        />
        <VoteComp
          num='2'
          title='Female Most Enterprising Student of the Year -'
          ////sponsor='////sponsored by Unified realty'
          nominees={nomineesFemaleMostEnterprising}
            value={femaleMostEnterprising}
            onChange={setFemaleMostEnterprising}
        />
       {/* <VoteComp
        num='3'
        title='Entrepreneurial Brand of the Year - '
        ////sponsor='////sponsored by Ryder Autos'
        nominees={nomineesEntrepreneurialBrand}
            value={entrepreneurialBrand}
            onChange={setEntrepreneurialBrand}
       /> */}
        <VoteComp
        num='3'
        title=' Fashion Designer of the Year - '
        ////sponsor='////sponsored by Ay lil king couture'
        nominees={nomineesFashionDesign}
            value={fashionDesign}
            onChange={setFashionDesign}
       />
        <VoteComp
        num='4'
        title=' Photographer of the Year '
        ////sponsor=''
        nominees={nomineesPhotographer}
            value={photographer}
            onChange={setPhotographer}
       />
        
        <VoteComp
        num='5'
        title='Student brand of the Year'
        ////sponsor=''
        nominees={nomineesBrandInflu}
            value={brandInflu}
            onChange={setBrandInflu}
       />
          <VoteComp
        num='6'
        title=' CEO of the Year'
        ////sponsor=''
        nominees={nomineesCeo}
            value={ceo}
            onChange={setCeo}
       />
        <VoteComp
        num='7'
        title='Makeup Artist of the Year'
        ////sponsor=''
        nominees={nomineesMakeup}
            value={makeup}
            onChange={setMakeup}
       />
         {/* <VoteComp
        num='9'
        title=' PR of the Year'
        ////sponsor=''
        nominees={nomineesPr}
            value={pr}
            onChange={setPr}
       /> */}
         <VoteComp
        num='8'
        title=' Most Promising Enterprising Brand '
        ////sponsor='////sponsored by Genesis 360'
        nominees={nomineesMostPromising}
            value={mostPromising}
            onChange={setMostPromising}
       />
        <VoteComp
        num='9'
        title=' Fashion brand of the year '
        ////sponsor='////sponsored by Genesis 360'
        nominees={nomineesFashion}
            value={fashion}
            onChange={setFashion}
       />
        
        
    </div>
    <div className=' mt-6 flex gap-1'>
    <Button
        name='Submit'
    />
    
    </div>
   
    </form>
   
    </div>
  )
}

export default Vote