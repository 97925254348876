import React, {useEffect} from 'react'
import FosaFooter from '../../Components/FosaFooter'
import FosaNavBar from '../../Components/FosaNavbar'
import FosaSponsors from '../../Components/FosaSponsors'
import Content from '../../Components/General/Content'
import Topsec from '../../Components/General/Topsec'
import Vote from '../../Components/General/Vote'
import ReactGA from 'react-ga'
function General() {
  useEffect(() =>{
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <div>
        <FosaNavBar/>
        <Topsec/>
        <Content/>
        <FosaSponsors/>
        <Vote/>
        <FosaSponsors/>
        <FosaFooter/>
    </div>
  )
}

export default General