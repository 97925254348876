import React from 'react'
import Fresh from '../../images/freshicon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={Fresh} className=' mx-auto' alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-black font-[700] mb-2'>Fresh Student</h1>
                
                <p className=' text-[14px] md:text-2xl font-[400]'>The FRESH STUDENT CATEGORY award recognizes outstanding best new students  who are making significant contributions to their school and community. These students demonstrate leadership, academic excellence, and a commitment to service.<br></br><br></br>
The award celebrates their achievements and encourages them to continue their pursuit of excellence. They have usually tagged this the "Young And Getting It -YAGI". And this marks their point of growth; the surge of their EVOLUTION..</p>
            </div>
            
        </div>
    </div>
  )
}

export default Content