import axios from "axios";
const API = "https://apis.jaslineentertainment.com/api"

const getNominees = (id) =>{
    return axios.get(API + `/get/get_nominees.php?title_id=${id}`)
}

const getServices = {
    getNominees
}

export default getServices