import React from 'react'
import Votei from '../../images/vote.png'
import Entertainment from '../../images/enter.png'
import Entrepreneur from '../../images/entre.png'
import Fresher from '../../images/frei.png'
import General from '../../images/geni.png'
import Result from '../../images/grai.png'
import Recogn from '../../images/recog.png'
import VoteCom from './VoteCom'
function Vote() {
  return (
    <div className=' py-16 md:px-10 bg-green-white p-5 grid md:grid-cols-5 gap-4'>
        <div className=' md:col-span-2'>
            <img src={Votei} alt='vote' className=' mx-auto hidden md:block'/>
        </div>
        <div className=' md:col-span-3'>
            <div className=' grid md:grid-cols-2 gap-4'>
                <VoteCom
                    img={General}
                    header='General'
                    sub='Most popular male & female, Sport man of the year, Sport woman of the year, miss ebony etc.'
                    link='/fosa/general'
                />
                <VoteCom
                    img={Result}
                    header='Academic'
                    sub='Male & female best new student of the year, Academic best student of the year, etc.'
                    link='/fosa/academic'
                />
                 <VoteCom
                    img={Entrepreneur}
                    header='Entrepreneurship'
                    sub='Male & female most enterprising student of the year, Fashion designer of the year, CEO of the year etc.'
                    link='/fosa/entrepreneurship'

                />
                <VoteCom
                    img={Entertainment}
                    header='Entertainment'
                    sub='PR of the year, Social media influencer of the year, Artist of the year, DJ of the year, etc.'
                    link='/fosa/entertainment'
                />
                 <VoteCom
                    img={Fresher}
                    header='Fresh Student'
                    sub='Male rookie of the year, female rookie of the year, Next rated artist of the year, etc'
                    link='/fosa/freshstudent'

                />
                <VoteCom
                    img={Recogn}
                    header='Special Recognition'
                    sub='Male & female philanthropist of the year, Hall of fame, Political icon of the year.'
                    link='/fosa/recognition'

                />
            </div>
        </div>
    </div>
  )
}

export default Vote