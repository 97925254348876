import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import S1 from '../images/s1.png'
import S2 from '../images/24s.JPG'
import S22 from '../images/fs33.jpeg'
import S3 from '../images/fs44.jpeg'
import S4 from '../images/sf11.jpeg'
import S5 from '../images/ace.jpeg'
import S6 from '../images/ssp.jpeg'
import S7 from '../images/sff.jpeg'
import S8 from '../images/spp0.jpeg'
 import S9 from '../images/sb.jpeg'
 import S10 from '../images/se.jpeg'
class FosaSponsors extends Component{
    render(){
      const settings = {
       
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1000,
        cssEase: "linear"
      };
      return(
       
        <div className=" p-6  md:p-16 mt-5 md:mt-10">
          <h1 className=' text-black text-2xl md:text-5xl font-[700] text-center mt-5 mb-10 wow animate__animated animate__slideInLeft'>FOSA Award Sponsors</h1>
          <Slider {...settings}>
          {/* <div className="container">
              <img alt='logo' className=' m-1'  src={S2}/>
            </div> */}
            <div className="container">
              <img alt='logo' className=' m-1'  src={S3}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1' src={S1} />
            </div>
            {/* <div className="container">
              <img alt='logo' className=' m-1'  src={S6}/>
            </div> */}
            <div className="container">
              <img alt='logo' className=' m-1'  src={S22}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S2}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S22}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S3}/>
            </div>
            {/* 
            <div className="container">
              <img alt='logo' className=' m-1'  src={S22}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S5}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S7}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S8}/>
            </div>
            <div className="container">
              <img alt='logo' className=' m-1'  src={S9}/>
            </div> */}
            {/* <div className="container">
              <img alt='logo' className=' m-1'  src={S10}/>
            </div> */}
          </Slider>
        </div>
      );
    }
  }
  
  export default FosaSponsors;