import React, {useState} from 'react'
import Pay from '../../images/pay.png'
import Button from '../SelectValue/Button'
import Logo from '../../images/fosa.png'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import TextBox from '../SelectValue/TextBox'
import swal from 'sweetalert';
function Payment() {
    const [ticket, setTicket] = useState('')
    const [amount, setAmount] = useState(0)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [pNum, setPNum] = useState('')
    const [ticketQuantity, setTicketQuantity] = useState(1);
    let totalAmount = amount * ticketQuantity;
    const handleTicketSelection = (e) => {
        setTicket(e.target.value);
        if (e.target.value === "primate") {
          setAmount("2000");
        } else if (e.target.value === "human") {
          setAmount("2000");
        } else{
            setAmount('')
        }
      };
     
    
     const config = {
        public_key: 'FLWPUBK-29b5e0f7990984e4a446db7a2e6f4e4e-X',
        tx_ref: Date.now(),
        amount: totalAmount ,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        // subaccounts: [
        //   {
        //     id: "RS_0BED2AA2EDB927693B3579908C7D9F17", // Your subaccount ID
        //   },
        // ],
        customer: {
          email: email,
          phonenumber: pNum,
          name: name,
        },
        customizations: {
          title: 'Back2School Fiesta',
          description: 'Jasline party',
          logo: {Logo},
        },
      };
      const handleQuantityChange = (event) => {
        setTicketQuantity(Number(event.target.value));
      }
    
     
    
      const handleFlutterPayment = useFlutterwave(config);

      const handleSubmit = (e) =>{
        e.preventDefault()
       
        handleFlutterPayment({
          callback: (response) => {
          console.log(response);
           closePaymentModal() // this will close the modal programmatically
           if(response.status === 'successful'){
            swal(' Payment was successful, check mail for receipt or contact +2349055076022 on WhatsApp for any complains')
                .then((value) => {
                    window.location.reload()
                  });
           }else{
            swal('Thanks. Contact +2349055076022 on WhatsApp for any complains')
            .then((value) => {
                window.location.reload()
              });
           }
            },
           onClose: () => {
                swal('Having issues with payment?  Contact +2349055076022 on WhatsApp for any complains')
                .then((value) => {
                    window.location.reload()
                  });
           },
           });
     }

  return (
    <div className=' pt-10 mx-4 mb-10 grid md:grid-cols-2 gap-2 md:mx-10'>
            <div className=' hidden md:block'>
                <img src={Pay} className=' mx-auto' alt='Pay'/>
            </div>
            <div className=' mt-8'>
            <div className=' md:w-1/2 mx-auto'>
            <form onSubmit={handleSubmit}>
                <div className=' my-5'>
                <TextBox
                        label='Full Name'
                        type='text'
                        value={name}
                        onChange={setName}
                    />
                </div>
                <div className=' my-5'>
                <TextBox
                        label='Email'
                        type='email'
                        value={email}
                        onChange= {setEmail}
                    />
                </div>
                <div className=' my-5'>
                <TextBox
                        label='Phone Number'
                        type='tel'
                        value={pNum}
                        onChange={setPNum}
                    />
                </div>
                <div>
                <label className=' block mb-2'>What type of ticket are you paying for?</label>
                <select className=' w-full rounded-lg p-4 border border-[gray-200] bg-[#F8F8F8]' value={ticket} onChange={handleTicketSelection} required>
                <option value="">Select a ticket</option>
                <option value="primate">Back2School</option>
               
                </select>
                </div>

                <div className=' my-5'>
                <TextBox
                        label='Amount'
                        value={amount}
                       
                    />
                </div>
                <div>
                <label className=' block mb-2'> Select the number of tickets</label>
                <select className=' p-4 w-full border mb-5 outline-0' value={ticketQuantity} onChange={handleQuantityChange}>
                        {[...Array(10).keys()].map((num) => (
                          <option key={num + 1} value={num + 1}>
                            {num + 1}
                          </option>
                        ))}
                      </select>
                </div>

                    {amount !== 0 &&
                      <p className=' mb-3'>Total Amount: ₦{totalAmount}</p>
                    }
                   
                <div className=' grid grid-cols-1'>
                    <Button
                        name='Make Payment'
                       
                    />
                </div>
                </form>
            </div>
               
            </div>
    </div>
  )
}

export default Payment