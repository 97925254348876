import React from 'react'
import General from '../../images/genicon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={General} className=' mx-auto' alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-black font-[700] mb-2'>GENERAL CATEGORIES</h1>
                
                <p className=' text-[14px] md:text-2xl font-[400]'>Kings and Queens, this section is fully dedicated towards Individuality that makes up Generality.<br></br> <br></br>
                    Ranging from the Best Dressed, Most Popular, to the most Beautiful faces of the students, the Best Sportsmen, Notable Icons in different spheres, and groups and pairs of impressive characters.<br></br><br></br>
                    Come forth y'all, let's celebrate your evolution at this year's FOSA.</p>
            </div>
            
        </div>
    </div>
  )
}

export default Content