import React from 'react'
import Fresh from '../../images/entericon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 md:pt-16 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={Fresh} className=' mx-auto'  alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-[black] font-[700] mb-2'>Entertainment</h1>
                
                <p className=' text-[14px]  md:text-2xl font-[400]'>The ENTERTAINMENT CATEGORY award recognizes students who have demonstrated exceptional talent and creativity in the art environment. This award celebrates those who have brought joy and entertainment to their peers and the community through their performances and productions in areas such as music, dance, theatre, and film.<br></br><br></br>
These students have shown passion and dedication to their craft, and their talent and enthusiasm have inspired others to pursue their artistic dreams. This award celebrates the value of the arts and the contributions of these talented students to the world of
entertainment.</p>
            </div>
            
        </div>
    </div>
  )
}

export default Content