import React from 'react'
import Call from '../../images/phone.png'
import IG from '../../images/ig.png'
import Mail from '../../images/mail.png'
import Sup from '../../images/sup.png'
import Line from '../../images/Linee.png'
function Contact() {
  return (
    <div id='contact' className=' hidden md:block w-3/4 mt-5 mx-auto mb-7 p-6 bg-[#FFFCF5] text-black border-orange-p rounded-lg border'>
        <div className=' mb-4'>
            <h1 className=' text-5xl font-[700] mb-3 text-center'>Contact us</h1>
            <p className=' text-center'>You can always talk to us on:</p>
        </div>
        <div className=' flex justify-center gap-3'>
            <div>
                <img src={IG} width='48' height='49' className=' mx-auto' alt='ig'/>
                <p className=' text-center text-black'>@jasline_entertainment</p>
            </div>
            <div>
            <img src={Line} alt='line'/>
            </div>
            <div>
                <img src={Mail} width='48' height='49' className=' mx-auto' alt='Mail'/>
                <p className=' text-center text-black'>info@jaslineentertainment.com</p>
            </div>
            <img src={Line} alt='line'/>
            <div>
                <img src={Sup} width='48' height='49' className=' mx-auto' alt='Sup'/>
                <p className=' text-center text-black'>+2349055076022</p>
            </div>
            <img src={Line} alt='line'/>
            <div>
                <img src={Call} width='48' height='49' className=' mx-auto' alt='Call'/>
                <p className=' text-center text-black'>+2349055076022</p>
            </div>
        </div>
    </div>
  )
}

export default Contact