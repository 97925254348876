import React from 'react'
import { Link } from 'react-router-dom'

function TopSec() {
  return (
    <div id='home' className='bg '>
        <div className=' grid h-full  place-items-center   '>
                        <div className='bg-primary-color5 p-3 m-3 md:p-7 rounded-lg wow animate__animated animate__slideInLeft'>
                        <h1 className=' text-white text-[24px] text-center md:text-[48px] font-[700] mb-2'>Jasline Entertainment</h1>
                         <p className=' text-white text-[12px] md:text-[24px] text-center font-[400] mb-4'>...is the life of the party — we are the party.</p> 
                            <div className=' flex justify-center'>
                                <div className=' relative'>
                                 <Link to='/fosa'> <button 
                                className=' border-b-[3px] border-r-[3px] border-t border-l bg-transparent border-r-orange-p border-b-orange-p  px-4  py-3 rounded-lg text-white'
                                type='submit'>Fosa Awards</button> </Link>
                                </div>
                                <div className=' pl-3'>
                                <Link to='/ticket'>
                                  <button 
                                className=' border-b-[3px] border-r-[3px] border-t border-l bg-white border-r-green-p border-b-green-p  px-4  py-3 rounded-lg text-black'
                                type='submit'>Get Tickets</button> 
                                </Link>
                                </div>
                            </div>
                        </div>
               
        </div>
    </div>
  )
}

export default TopSec