import React,{useEffect} from 'react'
import Content from '../../Components/Entertainment/Content'
import Topsec from '../../Components/Entertainment/Topsec'
import Vote from '../../Components/Entertainment/Vote'
import FosaFooter from '../../Components/FosaFooter'
import FosaNavBar from '../../Components/FosaNavbar'
import FosaSponsors from '../../Components/FosaSponsors'
import ReactGA from 'react-ga'
function Entertainment() {
  useEffect(() =>{
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <div>
        <FosaNavBar/>
        <Topsec/>
        <Content/>
        <FosaSponsors/>
        <Vote/>
        <FosaSponsors/>
        <FosaFooter/>
    </div>
  )
}

export default Entertainment