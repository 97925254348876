import React from 'react'
import Fosa from '../../images/lf.JPG'
import Timer from './Timer'
function Upcomingevent() {
  return (
    <div className='bg-[#191919] p-8 md:p-24 '>
            <h1 className=' text-center  text-white text-5xl font-[700] mb-5'>Upcoming Events</h1>
            <div className=' m-3  border-2 border-white p-3 md:p-16 lg:w-3/4 mx-auto'>
                <div className=' grid md:grid-cols-2 gap-2'>
                <div>
                    <img src={Fosa} alt='fosa'/>
                </div>
                <div className=' px-4'>
                    <p className=' text-white'>The Federal University of Agriculture, Abeokuta, has long been a beacon of excellence -nurturing talents and shaping great minds.
                    <br></br><br></br>
                    This year, we gather to honour the golden legacy of our institution, celebrating the individuals who have left indelible marks on our community.
                    <br></br><br></br>
                    The Aurum represents the gold standard of achievements, a pinnacle of success that inspires us to reach higher heights.
                    <br></br><br></br>
                    We recognise the trailblazers, the daring innovators, and the change makers who pave the way for generations to come.
                    <br></br><br></br>
                    Their legacy is our inheritance, a treasure trove of undaunted wisdom knowledge,and pure excellence.
                    <br></br><br></br>
                    Join us as we unveil 'The Aurum', a tribute to the golden legacy that makes FUNAAB a shining example of sheer excellence.
                    <br></br><br></br>
                    Let us celebrate the ones who have made lasting impacts, and let their stories inspire us to forge our own path to greatness.
                      </p>
                </div>
                </div>
                <Timer/>
            </div>
    </div>
  )
}

export default Upcomingevent