import React, {useEffect, useState} from 'react'
import getServices from '../../Services/get-services';
import Button from '../SelectValue/Button';

import swal from 'sweetalert';
import Loading from '../SelectValue/Loadingdropdown';
import ReactGA from 'react-ga'
import VoteComp from '../VoteComp';
import postService from '../../Services/post-services';

function Vote() {
    const[ nomineesMaleBest, setNomineesMaleBest] = useState([])
    const[ nomineesFemaleBest, setNomineesFemaleBest] = useState([])
    const[ nomineesAcademicBest, setNomineesAcademicBest] = useState([])
    const[ nomineesHoc, setNomineesHoc] = useState([])
    const[nomineesRookieMale, setNomineesRookieMale] = useState([])
    const[nomineesRookieFMale, setNomineesRookieFMale] = useState([])
    const [loading, setLoading] = useState(false)
    const [academicBest, setAcademicBest] = useState('');
    const [maleBest, setMaleBest] = useState('')
  const [ femaleBest, setFemaleBest] = useState('')
  const [maleRookie, setMaleRookie] = useState('')
  const [ femaleRookie, setFemaleRookie] = useState('')
  const [ hoc, setHoc] = useState('')
    const data = {
        votes: [
          // {
          //   nominee_id: maleBest,
          //   category_id: 65
          // },
          // {
          //   nominee_id: femaleBest,
          //   category_id: 66
          // },
          {
            nominee_id: academicBest,
            category_id: 65
          },
          {
            nominee_id: hoc,
            category_id: 45
          }
        ]
      };
    
    const handleSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
            ReactGA.event({'category': 'Academic', 'action': 'Submitvote','label': 'vote o' })
            data.votes = data.votes.filter((vote) =>  vote.nominee_id !== '')
            console.log(data)
            postService.votesNomines(data).then(
              (response) => {
                  setLoading(false)
                  console.log(response.data)
                  swal("Voting completed Successfully")
                    .then((value) => {
                      window.location.reload()
                    });
                 
                },
                (error) => {
                  setLoading(false)
                    return  swal('Voting failed')
                    .then((value) => {
                      window.location.reload()
                    });
                  }
            )
    }
    useEffect(() =>{
        getServices.getNominees(5).then(
          (response) => {
            setNomineesMaleBest(response.data.nominies['BEST NEW STUDENTS MALE'])
            setNomineesFemaleBest(response.data.nominies['BEST NEW STUDENTS FEMALE'])
            setNomineesAcademicBest(response.data.nominies['BEST STUDENTS’ ACADEMICS ']);
            
            setNomineesRookieFMale(response.data.nominies['ROOKIE OF THE YEAR FEMALE']);
            setNomineesRookieMale(response.data.nominies['ROOKIE OF THE YEAR MALE']);
            console.log(response.data)
            
          },
          (error) => {
           
    
              
          }
        )
        getServices.getNominees(4).then(
          (response) => {
            setNomineesHoc(response.data.nominies['BEST HOC']);
            
          },
          (error) => {
           
    
              
          }
        )
       
      }, [])
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    <Loading
        open={loading}
    />
    <h1 className=' text-4xl text-white text-center text-[700] mb-4'>Vote Now</h1>
    <form onSubmit={handleSubmit}>
    <div className=' grid md:grid-cols-3 gap-4'>
        {/* <div>
        <p className=' text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>1</span> Male Best New Student of the Year</p>
    <div className=' mt-2'>
        <VoteButton
            nominees={nomineesMaleBest}
            value={maleBest}
            onChange={setMaleBest}
        />
    </div>
        </div>
        <div>
        <p className=' text-white'><span className=' text-black p-2 rounded-lg mr-2 bg-primary-color1'>2</span> Female Best New Student of the Year</p>
    <div className=' mt-2'>
        <VoteButton
            nominees={nomineesFemaleBest}
            value={femaleBest}
            onChange={setFemaleBest}
        />
    </div>
        </div> */}
        <VoteComp
          num='1'
          title='Academic Best Student of the Year'
          //sponsor={"//sponsored by Marksman Travel and Tour"}
          nominees={nomineesAcademicBest}
            value={academicBest}
            onChange={setAcademicBest}
        />
        <VoteComp
          num='2'
          title='Best HOC of the Year'
          nominees={nomineesHoc}
            value={hoc}
            onChange={setHoc}
        />
       {/* <VoteComp
          num='3'
          title='Best New Student Male'
          nominees={nomineesMaleBest}
            value={maleBest}
            onChange={setMaleBest}
        />
        <VoteComp
          num='4'
          title='Best New Student Female'
          nominees={nomineesFemaleBest}
            value={femaleBest}
            onChange={setFemaleBest}
        />
        <VoteComp
          num='5'
          title='Rookie of the year Female'
          nominees={nomineesRookieFMale}
            value={femaleRookie}
            onChange={setFemaleRookie}
        />
         <VoteComp
          num='6'
          title='Rookie of the year Male'
          nominees={nomineesRookieMale}
            value={maleRookie}
            onChange={setMaleRookie}
        /> */}
    </div>
    <div className=' mt-6 flex gap-1'>
    <Button
        name='Submit'
    />
    
    </div>
   
    </form>
   
    </div>
  )
}

export default Vote