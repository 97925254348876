import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../SelectValue/Button'

function VoteCom({img,header, sub, link}) {
  return (
    <div className=' border md:mx-5 border-[#27AE61] mb-10 bg-[#F8F8F8] rounded-lg p-4 relative'>
        <img src={img} alt='imm' className=' absolute -top-6 left-4'/>
        <h1 className=' text-[32px] mt-6 font-[700] mb-2'>{header}</h1>
        <p className=' text-[16px] font-[400] mb-4'>{sub}</p>
        <div className=' flex'>
        <Link to={link}>
            <Button
                name='Vote'
            />
        </Link> 
        </div>
        
    </div>
  )
}

export default VoteCom