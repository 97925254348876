import React from 'react'
import VoteButton from './SelectValue/Votebutton'

function VoteComp({num, title, sponsor, nominees, value, onChange}) {
  return (
    <div>
    <div className=' flex gap-1 '>
        <div className=' flex flex-col justify-center'>
        <span className=' text-black p-2 rounded-lg  mr-2 bg-primary-color1'>{num}</span>
        </div>
        <div>
        <p className=' text-white'> {title}  <span className='  animate-pulse text-2xl'>{sponsor}</span></p>
        </div>
    </div>
   
        <div className=' mt-2 wow animate__animated animate__zoomIn'>
            <VoteButton
                nominees={nominees?? []}
                value={value}
                onChange={onChange}
            />
        </div>
    </div>
  )
}

export default VoteComp