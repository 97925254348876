import React, {useEffect} from 'react'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/Navbar'
import Members from '../../Components/Team/Members'
import TeamPhoto from '../../Components/Team/TeamPhoto'
import Topsec from '../../Components/Team/Topsec'
import ReactGA from 'react-ga'
function Team() {
  useEffect(() =>{
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <div>
        <NavBar/>
        <Topsec/>
        <Members/>
        <TeamPhoto/>
        <Footer/>
    </div>
  )
}

export default Team