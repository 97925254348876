
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect, } from "react";
import Logo from '../images/logo.png'
import Button from '../Components/SelectValue/Button'
import SecondButton from "./SelectValue/SecondButton";
export default function NavBar() {
    const [navbar, setNavbar] = useState(false);
    const [clientWindowHeight, setClientWindowHeight] = useState("");
    const [changeBg, setChangeBg] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    });
  
    const handleScroll = () => {
      setClientWindowHeight(window.scrollY);
    };
    useEffect(() =>{
      let backgroundTransparacyVar = clientWindowHeight / 210;
      if (backgroundTransparacyVar < 1) {
          setChangeBg(true)
      }
      else{
        setChangeBg(false)
      }
    }, [clientWindowHeight])

    const scrollToSection = (elementRef) => {
        window.scrollTo({
          top: elementRef.current.offsetTop,
          behavior: "smooth",
        });
      };

    return (
        <nav className= {changeBg ? `w-full bg-[#DFF3E7] md:bg-transparent text-[#27AE61]  md:text-white    z-50 fixed `: `w-full text-[#27AE61] bg-[#DFF3E7]  z-50 fixed  ` }>
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <Link to='/'>
                            <img src={Logo} width='60' height='30' className=" "  alt=" logo" /> 
                        </Link>
                        <div className="md:hidden">
                            <button
                                className="p-2  text-white rounded-md outline-none focus:border-white focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 text-black h-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>

                                ) : (
                                    <svg  className="w-6 text-black h-6" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 14V16H3V14H15ZM18 7V9H0V7H18ZM15 0V2H3V0H15Z" fill="black"/>
                                    </svg>

                                  
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "block" : "hidden"
                        }`}
                    >

                    <div className=" flex justify-evenly">
                        <ul className="items-start justify-start space-y-8 md:flex md:space-x-6 md:space-y-0">
                        <li className="hover:text-green-p ">
                                <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 ' + (
                              !isActive ? ' hover:text-black' : '  text-black'
                            ))
                            }} to="/">
                                Home
                                </NavLink>
                            </li>
                            <li className=" hover:text-green-p">
                                <a href="#services">
                                Our Services
                                </a>
                            </li>
                            <li className=" hover:text-green-p">
                            <NavLink className={({isActive}) =>{
                            return( ' text-back-color2 ' + (
                              !isActive ? ' hover:text-black' : '  text-black'
                            ))
                            }} to='/team'>
                            Our Team
                                </NavLink>
                            </li>
                            <li className=" hover:text-green-p">
                            <a href='#pastevent'>
                            Past Event
                                </a>
                            </li>
                          
                            
                        </ul>
                          
                        </div>
                    </div>
                </div>
                <div className=" hidden md:flex md:order-2">
                <div className=" flex flex-col pr-3 justify-center">
              
                </div>
                <Link to='/fosa'>
               <SecondButton
                name='Fosa Awards'
               />
               </Link>
               <div className=" px-3">
               <Link to='/ticket'>
               <Button
                    name='Get Tickets'
                />
               </Link>
                
               </div>
                </div>
            </div>
        </nav>


    );
}