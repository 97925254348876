import React from 'react'
import Line from '../../images/line.png'
function Vision() {
  return (
    <div className='relative md:w-4/5 mb-5 mx-auto'>
    <div className='md:absolute md:-top-4 p-4 bg-green-white rounded-b-lg'>
        <div className=' flex gap-3'>
                <div className='wow animate__animated animate__zoomIn'>
                    <h1 className='  text-[32px] font-[700] mb-3 text-center '>Our Vision</h1>
                    <p className=' text-[16px] text-center'>To become the best entertainment branding label in Africa and one of the leading players in the global entertainment industry.</p>
                </div>
                     <img src={Line} className=' py-3' alt=' Line'/>
                <div className='wow animate__animated animate__zoomIn animate__delay-1s'>
                    <h1 className='  text-[32px] font-[700] mb-3 text-center '>Our Mission</h1>
                    <p className=' text-[16px] text-center'>To make your entertainment dreams come alive through professional analysis, organization, and execution.</p>
                </div>
        </div>
    </div>
    </div>
  )
}

export default Vision