import React from 'react'
import Logo from '../images/llogo.png'
import Call from '../images/call.png'
import Mail from '../images/mail2.png'
import Ig from '../images/igg.png'
import Sup from '../images/sup2.png'
import { Link } from 'react-router-dom'
function FosaFooter() {
  return (
    <div className=' bg-green-p p-4  md:p-6 md:px-16'>
        <div className=' grid grid-cols-2 md:pt-10 md:pb-6 gap-6 md:flex md:justify-between'>
            
            <div>
            <img src={Logo} width=' 50' className='' height='50' alt='Log'/>
            <p className=' block  text-white pt-2'>Jasline Entertainment<br></br>
              ...is the life of the party — we are the party.</p>
            </div>
            <div className=' ml-4 mt-1'> 
            <p className='  text-white text-[700] mb-2'>Vote Pages</p>
            <div className=' text-white md:pt-3 md:gap-3'>
            <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/fosa/general'>General</Link></p>
               <p className=' pb-2 hover:text-[#C0EBD3]'>  <Link to='/fosa/freshstudent'>Fresh Students</Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/fosa/entertainment'>Entertainment </Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/fosa/entrepreneurship'>Enterpreneur</Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/fosa/academic'>Academics</Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/fosa/recognition'>Social Recognition</Link></p>
            </div>
            </div>
            <div className=' ml-4 mt-1'> 
            <p className='  text-white mb-2 text-[700]'>Pages</p>
            <div className=' text-white md:pt-3 md:gap-3'>
            <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/'>Home</Link></p>
               <p className=' pb-2 hover:text-[#C0EBD3]'>  <Link to='/team'>Our Team</Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/'>Services </Link></p>
                <p className=' pb-2 hover:text-[#C0EBD3]'> <Link to='/'>About us</Link></p>
                
            </div>
            </div>
           
            <div>
                <p className=' text-center text-white mb-2'>Contact Us</p>
                <div className='  flex justify-center gap-2'>
               <a href='tel:+2349055076022' ><img src={Call} width=' 20' height='20' alt='Log'/></a> 
               <a href='https://www.instagram.com/jasline__entertainment/' target='_blank' rel='noreferrer'><img src={Ig} width=' 20' height='20' alt='Log'/></a> 
              <a href='mailto:info@jaslineentertainment.com' target='_blank' rel='noreferrer' ><img src={Mail} width=' 20' height='20' alt='Log'/></a>  
               <a href='https://wa.me/+2349055076022' target='_blank' rel='noreferrer' > <img src={Sup} width=' 20' height='20' alt='Log'/></a> 
                </div>
            </div>
        </div>
        <div className=' pt-5 pb-4'>
                <h1 className=' text-center text-white'>© Copyright Jasline Entertainment 2023</h1>
        </div>
    </div>
  )
}

export default FosaFooter