import React, {useEffect, useState} from 'react'
import getServices from '../../Services/get-services';
import Button from '../SelectValue/Button';
import swal from 'sweetalert';
import postService from '../../Services/post-services';
import Loading from '../SelectValue/Loadingdropdown';
import VoteButton from '../SelectValue/Votebutton';
import ReactGA from 'react-ga'
import VoteComp from '../VoteComp';

function Vote() {
      const [submit, setSubmit] = useState(false)
    const[ nomineesPopularMale, setNomineesPopularMale] = useState([])
    const [loading, setLoading] = useState(false)
    const [socialMedia, setSocialMedia] = useState('');
    const [artistYear, setArtistYear] = useState('');
    const [bestNew, setBestNew] = useState('')
    const [dj, setDj] = useState('')
    const [entertainmentPer, setEntertainmentPer] = useState('')
    const [dancer, setDancer] = useState('')
    const [promisingEnter,setPromisingEnter] = useState('')
    const [comedian, setComedian] = useState('')
    const [event, setEvent] = useState('')
    const [bestUSe, setBestuse] = useState('')
    const[pr, setPr] = useState('')
    const[nr, setNr] = useState('')
    const[creative, setCreative] = useState('')


    const [nomineesSocialMedia, setNominessSocialMedia] = useState([])
    const [nomineesArtistYear, setNominessArtistYear] = useState([])
    const [nomineesBestNew, setNominessBestNew] = useState([])
    const [nomineesDj, setNominessDj] = useState([])
    const [nomineesEntertainmentPer, setNominessEntertainmentPer] = useState([])
    const [nomineesDancer, setNominessDancer] = useState([])
    const [nomineesPromisingEnter, setNominessPromisingEnter] = useState([])
    const [nomineesComedian, setNominessComedian] = useState([])
    const [nomineesEvent, setNominessEvent] = useState([])
    const [nomineesBestUse, setNominessBestUse] = useState([])
    const [nomineesPr, setNominessPr] = useState([])
    const [nomineesNr, setNominessNr] = useState([])
    const [nomineesCreative, setNominessCreative] = useState([])
    const data = {
        votes: [
          {
            nominee_id: socialMedia,
            category_id: 55
          },
          {
            nominee_id: artistYear,
            category_id: 47
          },
          {
            nominee_id: bestNew,
            category_id: 46
          },
          {
            nominee_id: dj,
            category_id: 48
          },
          {
            nominee_id: entertainmentPer,
            category_id: 49
          },
          {
            nominee_id: dancer,
            category_id: 56
          },
          {
            nominee_id: promisingEnter,
            category_id: 71
          },
          {
            nominee_id: comedian,
            category_id: 53
          },
          {
            nominee_id: event,
            category_id: 52
          },
          {
            nominee_id: bestUSe,
            category_id: 51
          },
          {
            nominee_id: pr,
            category_id: 54
          },
          {
            nominee_id: nr,
            category_id: 50
          },
          {
            nominee_id: creative,
            category_id: 63
          },
        ]
      };
    
    const handleSubmit = (e) =>{
            e.preventDefault()
            
            setLoading(true)
            ReactGA.event({'category': 'Entertainment', 'action': 'Submitvote','label': 'vote o' })
           data.votes = data.votes.filter((vote) =>  vote.nominee_id !== '')
                console.log(data)
            postService.votesNomines(data).then(
              (response) => {
                  setLoading(false)
                  console.log(response.data)
                  swal("Voting completed Successfully")
                    .then((value) => {
                      window.location.reload()
                    });
                 
                },
                (error) => {
                  setLoading(false)
                    return  swal('Voting failed')
                    .then((value) => {
                      window.location.reload()
                    });
                  }
            )
              
          
    }
    useEffect(() =>{
        getServices.getNominees(2).then(
          (response) => {
            setNominessSocialMedia(response.data.nominies["SOCIAL MEDIA INFUNCER"]);
            setNominessArtistYear(response.data.nominies["ARTIST OF THE YEAR"]);
            setNominessBestNew(response.data.nominies["BEST NEW ACT"]);
            setNominessDj(response.data.nominies["DJ OF THE YEAR"]);
            setNominessEntertainmentPer(response.data.nominies["ENTERTAINMENT PERSONALITY"]);
            setNominessDancer(response.data.nominies["BEST DANCER"]);
            setNominessPromisingEnter(response.data.nominies["MOST PROMISING ENTETAINMENT PERSONALTY"]);
            setNominessComedian(response.data.nominies["COMEDIAN OF THE YEAR "]);
            setNominessEvent(response.data.nominies["EVENT ORGANIZER"]);
            setNominessBestUse(response.data.nominies["BEST USE OF SOCIAL MEDIA"]);
            setNominessPr(response.data.nominies["PR OF THE YEAR"]);
            setNominessNr(response.data.nominies["NEXT RATED ACT"]);
            // console.log(response.data)
            setNominessCreative(response.data.nominies["MOST CREATIVE STUDENTS "]);
          },
          (error) => {
           
    
            setNomineesPopularMale([]);
          }
        )
        getServices.getNominees(3).then(
          (response) => {
           
            
            
          },
          (error) => {
           
    
            setNomineesPopularMale([]);
          }
        )
       
      }, [])
  return (
    <div className=' bg-[#191919] py-20 px-8 md:px-16'>
    <Loading
        open={loading}
    />
    <h1 className=' text-4xl text-white text-center text-[700] mb-4'>Vote Now</h1>
    <form onSubmit={handleSubmit}>
    <div className=' grid md:grid-cols-3 gap-4'>
      {/* <VoteComp
        num='1'
        title='Social Media Influencer of the Year -'
        //sponsor={'//sponsored by Whateva brand'}
        nominees={nomineesSocialMedia}
            value={socialMedia}
            onChange={setSocialMedia}
      /> */}
       <VoteComp
        num='1'
        title='Artist of the Year - '
        // //sponsor='//sponsored by Ecstacy Studio'
        nominees={nomineesArtistYear}
            value={artistYear}
            onChange={setArtistYear}
      />
      <VoteComp
        num='2'
        title='Best New Act of the Year'
        nominees={nomineesBestNew}
            value={bestNew}
            onChange={setBestNew}
      />
       <VoteComp
        num='3'
        title='DJ of the Year'
        nominees={nomineesDj}
            value={dj}
            onChange={setDj}
      />
       <VoteComp
        num='4'
        title='Entertainment Personality of the Year'
        nominees={nomineesEntertainmentPer}
            value={entertainmentPer}
            onChange={setEntertainmentPer}
      />
        <VoteComp
        num='5'
        title='Dancer of the Year'
        //sponsor={"//sponsored by Ace Gadget & Accessories"}
        nominees={nomineesDancer}
            value={dancer}
            onChange={setDancer}
      />
        <VoteComp
        num='6'
        title='Most Promising Entertainment Personality of the Year - '
        // //sponsor='//sponsored by Cintinnati by Labongoes'
        nominees={nomineesPromisingEnter}
            value={promisingEnter}
            onChange={setPromisingEnter}
      />
        <VoteComp
        num='7'
        title='Comedian of the Year '
        nominees={nomineesComedian}
            value={comedian}
            onChange={setComedian}
      />
         <VoteComp
        num='8'
        title='Event Planner of the year'
        nominees={nomineesEvent}
            value={event}
            onChange={setEvent}
        />
         <VoteComp
        num='9'
        title='Best use of Social media'
        nominees={nomineesBestUse}
            value={bestUSe}
            onChange={setBestuse}
        />
         <VoteComp
        num='10'
        title='PR of the year -'
        //sponsor={'//sponsored by Whateva brand'}
        nominees={nomineesPr}
            value={pr}
            onChange={setPr}
        />
        {/* <VoteComp
        num='12'
        title='Next Rated Art'
        nominees={nomineesNr}
            value={nr}
            onChange={setNr}
        /> */}
        <VoteComp
        num='11'
        title='Most Creative '
        nominees={nomineesCreative}
            value={creative}
            onChange={setCreative}
        />
       
    </div>
    <div className=' mt-6 flex gap-1'>
    { <Button
        name='Submit'
    />}
    
    </div>
   
    </form>
   
    </div>
  )
}

export default Vote