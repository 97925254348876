import React, {useEffect} from 'react'
import Footer from '../../Components/Footer'
import NavBar from '../../Components/Navbar'
import Payment from '../../Components/Ticket/Payment'
import Topsec from '../../Components/Ticket/Topsec'
import ReactGA from 'react-ga'
function Ticket() {
  useEffect(() =>{
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <div>
       <NavBar/>
       <Topsec/>
       <Payment/>
       <Footer/>
    </div>
  )
}

export default Ticket