import React from 'react'

import T2 from '../../images/t2.JPG'
import T3 from '../../images/t3.JPG'
import T4 from '../../images/t4.JPG'
import T5 from '../../images/t5.JPG'
import T6 from '../../images/t6.JPG'
import T7 from '../../images/t7.JPG'
import T8 from '../../images/t8.JPG'
function TeamPhoto() {
  return (
    <div className='bg-[#1E222B] p-4 md:p-10'>
    <h1 className=' text-center  text-2xl mb-6 md:mb-16 mt-8 text-white font-[700] md:text-5xl '>Team Pictures</h1>
        <div className=' grid md:grid-rows-4 md:grid-flow-col gap-4'>
        <div className='md:row-span-3 wow animate__animated animate__zoomIn'>
                <img  height='300' className='w-full md:w-[400px] mx-auto' src={T2} alt='T1'/>
            </div>
            <div className='md:row-span-2 md:col-span-2 wow animate__animated animate__zoomIn'>
                <img src={T4} width='400' height='300' className=' mx-auto' alt='T1'/>
            </div>
            <div className='md:row-span-2 md:col-span-2 wow animate__animated animate__zoomIn'>
                <img width='400' height='300' className=' mx-auto' src={T7} alt='T1'/>
            </div>
        </div>
        <div className=' grid md:grid-rows-4 md:grid-flow-col gap-4'>
        <div className='md:row-span-2 mt-3 md:mt-0 wow animate__animated animate__zoomIn'>
                <img width='400' height='300' className=' mx-auto' src={T3} alt='T1'/>
            </div>
            <div className='md:row-span-2 md:col-span-2 wow animate__animated animate__zoomIn'>
                <img src={T5} width='400' height='300' className=' mx-auto' alt='T1'/>
            </div>
            <div className='md:row-span-2 md:col-span-2 wow animate__animated animate__zoomIn'>
                <img width='400' height='300' className=' mx-auto' src={T6} alt='T1'/>
            </div>
            <div className='md:row-span-2 md:col-span-2 wow animate__animated animate__zoomIn'>
                <img width='400' height='300' className=' mx-auto' src={T8} alt='T1'/>
            </div>
        </div>
           
            
           
           
       
    </div>
  )
}

export default TeamPhoto