import React from 'react'
import General from '../../images/specialicon.png'
function Content() {
  return (
    <div className=' grid py-5 px-5 mb-10 md:px-10 md:grid-cols-2 gap-5'>
        <div className=' hidden md:block'>
            <img src={General} className=' mx-auto' alt='General'/>
        </div>
        <div className=' flex flex-col justify-center'>
            <div>
                <h1 className=' text-2xl md:text-5xl text-center md:text-start text-black font-[700] mb-2'>Special Recognition</h1>
                
                <p className=' text-[14px] md:text-2xl font-[400]'>The SPECIAL RECOGNITION CATEGORY award is given to a student who has demonstrated exceptional talent, dedication, and commitment in a specific area of study or extracurricular activity. This student has consistently gone above and beyond in their pursuit of excellence.<br></br><br></br>
They have made unforgettable impacts on the school community and the students through their hard work and dedication. This award recognizes the outstanding achievements of this student and celebrates their unique talents and contributions.</p>
            </div>
           
        </div>
    </div>
  )
}

export default Content